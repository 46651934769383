export const en = {
  appBarTitle: {
    home: 'Home',
    control_tower: 'Control Tower',
    transit_time: 'Transit Time',
    risk_map: 'Risk Map',
    exceptions: 'Exceptions',
    co2_report: 'CO2 Report',
    air_cargo: 'Air Cargo',
    air_visibility: 'Air Cargo',
    accuracy: 'Accuracy',
    latency_accuracy: 'Coverage & Latency',
    port_congestion: 'Port Congestion',
    global_transit_time: 'Global Transit Time',
    airCargoTracking: 'Air Cargo',
    portCongestion: 'Port Congestion',
    freightAuditList: 'Freight Audit',
    freightAuditSingleInvoice: 'Single Freight Audit',

    containerTracking: 'Container Tracking',
    vesselTracking: 'Vessel Tracking',
    sailingSchedules: 'Sailing Schedules',

    container_logs: 'Container Logs',
    lookup_carrier: 'Carrier Lookup',
    lookup_port: 'Port Lookup',
    lookup_vessel: 'Vessel Lookup',
    reports: 'Reports',
    settings: 'Settings',
    singleContainerTracking: 'SingleContainerTracking',
    showJson: 'Show Json',
    jsonPreview: 'JSON Preview',
    disputeAnalysis: 'Dispute Analysis',
    emptyReturn: 'Empty Return',
    singleAirVisibility: 'singleAirVisibility',
    airVisibility: 'Air Cargo',
    tracking: 'Tracking',
    invoices: 'Invoices',
    portcastInvoice: 'Portcast Indices',
    contactSupport: 'Contact Support',
    orgSettings: 'Org Settings',
    portLookup: 'Ports Lookup',
    // temp
    sailing_schedule_dashboard: 'Sailing Schedule',
  },
  carrierLookupPage: {
    carriers: 'Carriers',
    fullName: 'Full Name',
    search: 'Search for Carrier or SCAC',
    synonyms: 'Synonyms',
  },
  common: {
    all: 'All',
    dashboard: 'Dashboard',
    requestReceived: 'Request Received',
    viewTransitTimeDashboard: 'View historical transit time',
    requestReceivedMessage: 'Thank you for getting in touch with us. Our team will contact you within the next 48 hours.',
    noThanks: 'No Thanks',
    contactUs: 'Contact Us',
    contactUsToUnlock: 'Contact Us to Unlock',
    dataUnavailable: 'Data Unavailable',
    co2Emission: 'C02 Emission',
    emission: 'Emission',
    locked: 'Locked',
    predictionLocked: 'Prediction Locked',
    preferences: 'Preferences',
    iAccept: 'I accept the',
    termsOfUse: 'Terms of Use',
    openInNewWindow: 'Opens in new window',
    unavailable: 'Unavailable',
    availableSoon: 'To be available soon',
    send: 'Send',
    portCongestion: 'Port Congestion',
    developerTooling: 'Developer Tooling',
    activeOrg: 'Active Org',
    actual_arrival: 'Actual Arrival', // v2
    added_on: 'Added On',
    anchorageStay: 'Anchorage Stay',
    apiDocumentation: 'API Documentation',
    archive: 'Archive',
    archived: 'Archived',
    arrival_status: 'Arrival Status',
    arrive_at_pod: 'Arrive At POD',
    arrive_at_pot: 'Arrive At POT',
    arrived: 'Arrived',
    arriving: 'Arriving',
    ts_waiting_time_in_days: 'TS Wait time(Days)',
    is_transhipment: 'Arriving',
    bls: 'bls',
    booking_no_: 'Booking No.',
    byCarrier: 'by Carrier',
    cancel: 'Cancel',
    carriers: 'Carriers',
    confirm: 'Confirm',
    confirmMessage: 'Are you sure you want to proceed?',
    consignee: 'Consignee ',
    container: 'Container',
    containerNotMappedWithBL: 'Container Not Mapped With BL',
    containerNotTrackedByCarrier: 'Container Not Tracked by Carrier',
    containers: 'Containers',
    contents: 'Contents',
    copied: 'Copied',
    copy: 'Copy',
    count: 'count',
    days: 'Days',
    delay_in_days: 'Delay in Days',
    delete: 'Delete',
    depart_at_pol: 'Depart At POL',
    depart_at_pot: 'Depart At POT',
    direct: 'direct',
    discharge: 'Discharge',
    discharged: 'Discharged',
    done: 'Done',
    download: 'Download',
    downloadAll: 'Download All',
    early: 'Early',
    earlyJourney: 'Early Journey',
    edit: 'Edit',
    email: 'Email',
    emptyReturn: 'Empty Return',
    error: 'Error',
    errors: 'Errors',
    events: 'Events',
    exceptions: 'Control Dashboard',
    from: 'from',
    gateOut: 'Gate Out',
    gatein_at_pol: 'Gate In At POL',
    gateout_at_pod: 'Gate Out At POD',
    gateout_at_pol: 'Gate Out At POL',
    importer: 'Importer',
    inThis: 'in this',
    incidentList: {
      anchorageStay: 'Anchorage Stay',
      nonOptimalRoute: 'Non Optimal Route',
      outdatedSchedule: 'Outdated Schedule',
      portStay: 'Port Stay',
    },
    helpCenter: 'Help Center',
    incidents: 'incidents',
    init: 'Init',
    internal_ref_no_: 'Internal Ref. No.',
    late: 'Late',
    load_at_pol: 'Load At POL',
    load_at_pot: 'Load At POT',
    loading: 'Loading ...',
    logout: 'Logout',
    logs: 'Logs',
    lookup: 'Lookup',
    new: 'new',
    noDataFromCarrier: 'No Data from Carrier',
    nonOptimalRoute: 'Non Optimal Route',
    of: 'of',
    okay: 'Okay',
    onTime: 'On Time',
    others: 'Others',
    or: 'OR',
    organization: 'Organization ',
    outdatedSchedule: 'Outdated Schedule',
    password: 'Password',
    pod: 'POD',
    pol: 'POL',
    pol_waiting_time_in_days: 'POL Waiting Time in Days',
    portNotInSchedule: 'Port Not in Schedule',
    portStay: 'Port Stay',
    ports: 'Ports',
    predicted_arrival: 'Predicted Arrival',
    proceed: 'Proceed',
    refreshDataConfirmMessage: 'Are you sure you want to refresh {total} containers data?',
    reindexDataConfirmMessage: 'Are you sure you want to Reindex data?',
    releaseNotes: 'Support Desk',
    return_at_pod: 'Return At POD',
    save: 'Save',
    searchOrgs: 'Search Orgs',
    selected: 'Selected',
    showAll: 'Show All',
    status: 'STATUS',
    statusListV2: {
      UPLOADED: { status: 'Container Uploaded', info: 'No data available at the moment', color: '' },
      BL_NOT_FOUND: { status: 'BL Not Found', info: 'No data available at the moment', color: '' },
      CNTR_NOT_FOUND: { status: 'Container Not Found', info: 'No data available at the moment', color: '' },
      INVALID_BL_NO: { status: 'Invalid BL No', info: '', color: '' },
      CNTR_NOT_MAPPED_TO_BL: { status: 'Container Not Mapped With BL', info: 'Carrier hasn\'t updated data', color: '' },
      EARLY_JOURNEY: { status: 'Early Journey', info: 'Carrier hasn\'t updated data', color: '' },
      JOURNEY_IN_PROGRESS: { status: 'Journey In Progress', info: '', color: '' },
      JOURNEY_COMPLETED: { status: 'Journey Completed', info: '', color: '' },
    },
    statusList: {
      'Arrived': 'Arrived',
      'NO_DATA_RECEIVED_FROM_CARRIER': 'No Data from Carrier',
      'BL_NOT_FOUND': 'BL Not Found',
      'BULK_INIT': 'Preparing Predictions',
      'CONTAINER_IDLE_AFTER_GATEOUT': 'Container Idle After Gate Out',
      'CONTAINER_NOT_FOUND': 'Container Not Found',
      'CONTAINER_NOT_MAPPED_WITH_BL': 'Container Not Mapped With BL',
      'CONTAINER_RETURNED_TO_CARRIER': 'Container Returned to Carrier',
      'INIT': 'Preparing Predictions',
      'NO_DATA_FROM_PROVIDER': 'No Data from Carrier',
      'On Time': 'On Time',
      'Preparing Predictions': 'Preparing Predictions',
      'SUCCESS': 'Success',
      'SUCCESS_EARLY_JOURNEY': 'Early Journey',
      'TARGET_PORT_NOT_IN_ROTATION': 'Port Not in Schedule',
      'earlyByXDays': 'Arriving Early by {days} Day(s)',
      'lateByXDays': 'Arriving Late by {days} Day(s)',
      'delayByDays': 'Delay {days} day(s)',
    },
    status_code: 'Status Code',
    stop: 'stop',
    stops: 'stops',
    supplier: 'Supplier',
    switchActiveOrg: 'Switch Active Org',
    switchTab: 'right now please switch to another Tab',
    t_m_s_ref_no_: 'TMS Ref. No.',
    t_s: 'T/S',
    thereAreNo: 'There are no',
    unique: 'Unique',
    unknownError: 'Unknown Error',
    unload_at_pod: 'Unload At POD',
    unload_at_pot: 'Unload At POT',
    updatedSuccessfully: 'Updated Successfully',
    updated_on: 'Updated On',
    uploadLogs: 'Upload Logs',
    vessel: 'Vessel',
    vesselTracking: 'Vessel Tracking',
    vessels: 'Vessels',
    viewProfile: 'View Profile',
    makeAsDefault: 'Make as Default',
    containerNumber: 'Container Number',
    billNumber: 'Bill of lading number',
    organisation: 'Organisation',
    addedUpdated: 'Added & Updated',
    exceptionsIncidents: 'Exceptions & Incidents',
    poNumber: 'PO Number',
    tmsRefNumber: 'TMS Ref Number',
    content: 'Content',
    tableView: 'Table View',
    calenderView: 'Calender View',
    mapView: 'Map View',
    customLate: 'Custom Late',
    viewName: 'View Name',
    viewDescription: 'View Description',
    optional: 'Optional',
    setAsDefaultView: ' Set as default view.',
    portOfLoading: 'Port of Loading',
    portOfDestination: 'Port of Destination',
    portOfTs: 'Port of Ts',
    transhipment: 'Transhipment',
    transhipments: 'Transhipment(s)',
    portOfDeparture: 'Port of Discharge',
    search: 'Search...',
    routeChange: 'Route Change',
    anchorageDelay: 'Anchorage Delay',
    portSequenceChange: 'Port sequence change',
    carrierConsideringDelay: 'Carrier considering delay',
    weatherAdversity: 'Weather Adversity',
    delayAtUpcomingPort: 'Delay at upcoming port',
    carrierDataMissing: 'Carrier Data Missing',
    invalidInput: 'Invalid Input',
    remove: 'Remove',
    filters: 'Filters',
    loadingPleaseWait: 'Loading... Please wait',
    original: 'Original',
    portcast: 'Portcast',
    clearAll: 'Clear All',
    active: 'Active',
    bookmarked: 'Bookmarked',
    bookmark: 'Bookmark',
    filterAllMessage: 'All: All your containers, including Active, Archived, and With Error.',
    filterActiveMessage: 'Active: Containers that are actively tracked. This list excludes containers With Error or Archived.',
    filterNewMessage: 'New: Containers that were uploaded in the last 24 hours.',
    filterLateMessage: 'Late: All of the active containers that are arriving late.',
    filterArrivedMessage: 'Arrived: The following containers have arrived at POD.',
    filterBookmarkedMessage: 'Bookmarked: The following containers have been bookmarked by one of the users in this org.',
    selectAllContainers: 'Select all {count} containers',
    scheduleAlertMessage: 'Do you want to schedule alerts for these containers? Receive notifications on key container milestones delivered to your email or mobile number',
    scheduleAlerts: 'Schedule Alerts',
    dontSchedule: 'Don\'t schedule',
    bookmarkContainerMessage: 'Do you want to bookmark these container(s)? It will help you to prioritise containers that are important.',
    bookmarkContainers: 'Bookmark Containers',
    dontBookmark: 'Don\'t Bookmark',
    downloadContainerMessage: 'Do you want to download the container(s)?',
    downloadVesselMessage: 'Do you want to download the vessel(s)?',
    download300ContainerMessage: 'Do you want to download the selected container(s) ? Selection of over 300 containers might take longer to process.',
    download300VesselMessage: 'Do you want to download the selected vessel(s) ? Selection of over 300 vessel might take longer to process.',
    dontDownload: 'Cancel',
    shareViaEmail: 'Share via Email',
    downloadNow: 'Download Now',
    archiveContainerMessage: 'Do you want to archive the container(s)? Archiving will stop tracking for the selected container(s).',
    archiveContainer: 'Archive  Container',
    dontArchive: 'Don\'t archive',
    deleteContainerMessage: 'Do you want to Delete the container(s)? Deleting a container is permanent. It will be removed from your listing.',
    deleteVesselsMessage: 'Deleting a vessel is permanent. It will be removed from you listing.',
    deleteContainer: 'Delete Container',
    deleteVessel: 'Delete Vessel',
    dontDelete: 'Don\'t delete',
    eta: 'ETA',
    carrierVessel: 'CARRIER/VESSEL',
    action: 'ACTION',
    searchByContainer: 'Search by Container, B/L or PO Number',
    searchByPodPolTs: 'Search by POL, POD or T/S',
    searchByVesselCarrier: 'Search by Vessel or Carrier',
    searchByCustomer: 'Search by Customer',
    searchByVessel: 'Search by Vessel No or  B/L no',
    applyGroupBy: 'Apply Group by',
    applySort: 'Apply Sort',
    showContainers: 'Show Containers',
    switchOrganisation: 'Switch Organisation',
    showCountContainers: 'Show {count} Containers',
    showCountVessels: 'Show {count} Vessels',
    applyFilters: 'Apply Filter',
    saveView: 'Save View',
    savedViews: 'Saved Filters',
    savedFilters: 'Saved Filters',
    selectView: 'Select View',
    addNew: 'Add New',
    chooseView: 'Choose View',
    applyView: 'Apply View',
    asDefault: 'as Default',
    set: 'Set',
    viewNameError: 'View name already exists',
    lateByDays: 'Late by {start} to {end} day(s)',
    lateByDaysNumber: 'Late by {count}+ day(s)',
    latestEvents: 'Latest Events',
    masterOrg: 'Master Org',
    emptyGateOutAtPOL: 'Empty Gate Out (at POL)',
    gateInFullAtPOL: 'Gate In Full (at POL)',
    loadedAtPOL: 'Loaded (at POL)',
    departedFromPOL: 'Departed (from POL)',
    arrivalAtPOD: 'Arrival (at POD)',
    dischargedAtPOD: 'Discharged (at POD)',
    gateOutFullAtPOD: 'Gate Out Full (at POD)',
    emptyReturnAtPOD: 'Empty Return (at POD)',
    lateStatus: 'Late Status',
    created: 'Created',
    updated: 'Updated',
    aisSatellite: 'AIS Satellite data',
    containerNotFound: 'Container(s) not found. <br> Try removing some filters.',
    vesselsNotFound: 'Vessel(s) not found. <br> Try removing some filters.',
    comingSoon: 'Coming Soon',
    pleaseTryAgain: 'Please check again later and report for further investigation',
    port: 'Port',
    country: 'Country',
    pol_country: 'Pol Country',
    pod_country: 'Pod Country',
    clickToCopy: 'Click to copy',
    clickToCopyContainer: 'Click to copy Container Number',
    generatingPrediction: 'Generating prediction',
    latest: 'Latest',
    dontHaveAnAccount: 'Don’t have an account yet?',
    alreadyHaveAnAccount: 'Already have an account?',
    corporateEmailInvalidError: 'Please use corporate email to signup.',
    noOrgDialogTitle: 'Contact Administration',
    noOrgDialogDescription: 'You don\'t have org assigned, please contact administration.',
    thankYou: 'Thank You',
    needsAttention: 'Needs Attention',
    onTheWay: 'on The Way',
    targetPort: 'Target Port',
    targetPortEta: 'Target Port Eta',
    PredictedActual: 'Predicted * / Actual',
    scheduledArrival: 'Scheduled Arrival',
    errorCode: 'Error Code',
    showVessels: 'Show Vessels',
    selectAllVessels: 'Select all {count} vessels',
    restart: 'Restart',
    reIndexContainers: 'Reindex Containers',
    reIndexMessage: 'Are you sure you want to Reindex containers data?',
    refresh: 'Refresh',
    refreshVessels: 'Refresh Vessels',
    refreshVesselsMessage: 'Are you sure you want to refresh {count} vessels data?',
    showJson: 'Show Json',
    carrierTime: 'Carrier Time',
    somethingWentWrong: 'Something went wrong. Please try again later.',
    dataNotAvailable: 'No Data Available',
    noResults: 'No results',
    total: 'Total',
    somethingWentWrongTryAgainLatter: 'Something went wrong, please try again latter.',
  },
  containerPage: {
    actual: 'Actual',
    actualArrivalAscending: 'Actual Arrival - Ascending',
    actualArrivalDescending: 'Actual Arrival - Descending',
    addContainer: 'Add Container(s)',
    addContainers: 'Add Containers', // v2
    addNewContainers: 'Add New Containers', // v2
    addMoreContainers: 'ADD MORE CONTAINERS',
    addedOn: 'Added On',
    addedOnMasterOrg: 'Org / Added On',
    addedOnSortByItems: {
      created_asc: 'Created - Ascending',
      created_desc: 'Created - Descending',
    },
    allTab: 'All',
    archiveContainers: 'Archive Containers',
    archivedTab: 'Archived',
    arrival: 'Arrival',
    arrivalSortByItems: {
      pod_ais_actual_arrival_asc: 'Actual Arrival - Ascending',
      pod_ais_actual_arrival_desc: 'Actual Arrival - Descending',
      pod_portcast_predicted_arrival_asc: 'Predicted Arrival - Ascending',
      pod_portcast_predicted_arrival_desc: 'Predicted Arrival - Descending',
    },
    arrivalStatus: 'Arrival Status',
    arrivedAtPOD: 'Arrived at POD',
    arrivedAtTS: 'Arrived at T/S',
    arrivedTab: 'Arrived',
    arrivingTab: 'Arriving',
    billOfLading: 'Bill of Lading', // v2
    bookingInfo: 'Booking Info.',
    bookingInformation: 'Booking Information',
    bookingNo: 'Booking No.',
    bookingNumber: 'Booking Number',
    browseFiles: 'BROWSE FILES',
    bulkUpload: 'Bulk Upload',
    carrier: 'Carrier',
    carrierNo: 'Carrier No',
    carrierSCAC: 'Carrier / SCAC',
    carrierSCACVessels: 'Carrier SCAC / Vessels',
    clear: 'Clear',
    clearAppliedFilters: 'CLEAR APPLIED FILTERS',
    consignee: 'Consignee',
    containerArchiveSuccessful: 'Successfully Archived {count} Containers !!',
    containerEvent: 'Container Event',
    containerNo: 'Container No', // v2
    containerNoBl: 'Container No / BL',
    containerNotMappedWithBL: 'Container Not Mapped With BL',
    containerNotTrackedByCarrier: 'Container Not Tracked by Carrier',
    containerUploadErrorDuplicate: 'Duplicate Upload Attempt',
    containerUploadErrorInvalidContainer: 'Invalid Container No',
    containerUploadErrorMissingCarrier: 'Missing Carrier',
    containerUploadErrorMissingContainer: 'Missing Container No',
    containerUploadErrorInvalidCarrier: 'Invalid Carrier No',
    containerUploadErrorQuotaExceeded: 'Quota exceeded',
    contentBrand: 'Content / Brand',
    contents: 'Contents',
    contractualOriginalETA: 'Contractual / Original ETA (YYYY-MM-DD)',
    contractualOriginalETD: 'Contractual / Original ETD (YYYY-MM-DD)',
    customer: 'Customer',
    deletedAllInvalidText: 'you\'ve deleted all the invalid containers',
    departedFromPOL: 'Departed from POL',
    departedFromTS: 'Departed from T/S',
    departure: 'Departure',
    destination: 'Destination',
    discharge: 'Discharge',
    dischargedAtPOD: 'Discharged at POD',
    dischargedAtTS: 'Discharged at T/S',
    disclaimer: 'based on Initially Scheduled by Carrier date',
    downloadAll: 'Download All Containers',
    downloadContainers: 'Download Containers',
    downloadSample: 'Download Sample CSV',
    downloadSelected: 'Download Selected Containers',
    dropFilesHere: 'Drop Files here',
    earlyJourney: 'Early Journey',
    earlyTab: 'Early',
    emptyReturn: 'Empty Return',
    enterData: 'Enter your data here',
    exportErrorCSV: 'Export Errors as CSV',
    fileSupport: 'We support .CSV, .XLS and .XlSX',
    filterBookingNo: 'Filter by Booking Numbers',
    filterByBLs: 'Filter by BLs',
    filterByCarriers: 'Filter by Carriers',
    filterByContainerNos: 'Filter by Container Nos',
    filterByPODPorts: 'Filter by POD Ports',
    filterByPOLPorts: 'Filter by POL Ports',
    filterByTSPorts: 'Filter by TS Ports',
    filterByVessels: 'Filter by Vessels',
    filterConsignee: 'Filter by Consignee',
    filterContents: 'Filter by Contents',
    filterImporter: 'Filter by Importer',
    filterInternalRef: 'Filter by Internal Reference Numbers',
    filterPoNo: 'Filter by P.O Numbers',
    filterSupplier: 'Filter by Supplier',
    filterTmsRef: 'Filter by T.M.S Reference Numbers',

    finalVesselName: 'Final Vessel Name',
    firstVesselName: 'First Vessel Name',
    gateInFull: 'Gate In Full',
    gateOutEmpty: 'Gate Out Empty',
    gateOutFull: 'Gate Out Full',
    generateNewPredictions: {
      disabledTitle: 'Generating Predictions is Disabled for Arrived Containers',
      subTitle: 'We\'ll get you fresh ETAs for the Container',
      successMessage: 'Generating New Predictions. Please check back after 1-2 minutes',
      title: 'Generate New Predictions',
    },
    groupBy: 'Group By',
    groupingByUnique: 'Grouping by Unique',
    importer: 'Importer',
    initiallyScheduled: 'Initially Scheduled',
    plannedArrival: 'Initially Scheduled',
    planned: 'Initial',
    internalRef: 'Internal Ref',
    internalRefNumber: 'Internal Ref Number',
    invalidContainerNo: 'Invalid Container No.',
    journey: 'Journey',
    lateTab: 'Late',
    latestEvent: 'Latest Event',
    latestPredicted: 'Latest (Predicted)',
    latestScheduled: 'Latest Scheduled',
    loadedAtPOL: 'Loaded at POL',
    loadedAtTS: 'Loaded at T/S',
    loading: 'Loading',
    mapColumns: 'Map Columns',
    mapColumnsHeader: 'Does the selected row contain column headers?',
    mapColumnsTip: 'Select Corresponding Column from File',
    miscellaneousInformation: 'Miscellaneous Information',
    newTab: 'New',
    nextStepsInstruction:
            'The next step will allow you to match your spreadsheet columns to the right data points.',
    noDataFromCarrier: 'No Data from Carrier',
    noInvalidContainers: 'There are no invalid containers.',
    noSailingInfo: 'No Sailing Info',
    outboundHaulage: 'Outbound Haulage',
    outboundHaulageID: 'Outbound Haulage ID',
    packageQuantity: 'Package Quantity',
    poNo: 'P.O No.',
    poNumber: 'P.O Number',
    pod: 'POD',
    pol: 'POL',
    portNotinSchedule: 'Port Not in Schedule',
    predicted: 'Predicted',
    predictedActualArrival: 'Predicted / Actual Arrival',
    predictedArrivalAscending: 'Predicted Arrival - Ascending',
    predictedArrivalDescending: 'Predicted Arrival - Descending',
    preparingPredictions: 'Preparing Predictions',
    productDescription: 'Product Description',
    productDetails: 'Product Details',
    productInfo: 'Product Info.',
    productInformation: 'Product Information',
    refreshOrgData: 'Refresh Data',
    reindexData: 'Reindex Data',
    reviewContainers: 'Review Containers',
    sailingDates: 'Sailing Dates',
    sailingInfo: 'Sailing Info',
    sailingInfoNotAvailable:
            'Sailing info not available due to missing POL or POD information.',
    saleInfo: 'Sale Info',
    salesOrder: 'Sales Order',
    scheduled: 'Scheduled',
    searchContainers: 'Search Containers',
    selectAnotherRow: 'Select Another Row',
    showEtaHistory: 'Show ETA History',
    sortBy: 'Sort By',
    sortByAddedOnDate: 'Sort By Added On',
    sortByArrivalDate: 'Sort by Arrival Dates',
    source: 'Source',
    sourceFile: 'Source File',
    status: 'Status',
    statusIncidents: 'Status / Incidents',
    subtextAddContainers:
            'You can copy and paste multiple rows from your source file here.',
    success: 'Success',
    supplier: 'Supplier',
    supplierID: 'Supplier ID',
    title: 'Container Tracking',
    tmsRef: 'T.M.S Ref',
    tmsReferenceNumber: 'TMS Reference Number',
    ts: 'TS',
    updatedOn: 'Updated On',
    uploadAddContainers: 'Upload/Add Containers',
    uploadCompleted: 'Upload Completed',
    uploadFailed: 'Upload Failed',
    uploadFailedMessage: 'Uploading containers failed.',
    uploadMessage1: 'You\'ve uploaded',
    uploadMessage2: 'containers successfully',
    previousUploadInProgress: 'Your previous upload is still in progress, please try again later.',
    vessel: 'Vessel',
    vesselNotConfirmed: 'Vessel Not Confirmed Yet',
    vesselNotConfirmedTooltip: 'The Carrier is yet to confirm the vessel name for this leg. Check again in 48 hours',
    vesselVoyage: 'Vessel, Voyage',
    viewRecentUploadLogs: 'View Recently Uploaded Container Logs',
    search: 'Search',
    origin: 'Origin',
    selectPort: 'Select Port',
    selectCountry: 'Select Country',
    addingContainerInProgress: 'Adding container in progress...',
    AddCountContainers: 'Add {count} Containers',
    new: 'New',
    customFieldInformation: 'Custom Field Information',
    bookings: 'Bookings',
    uploadStatusText: '{upload} out of {total} {type} processed',
    addedContainersSuccessfully: 'container(s) successfully added',
    errorFound: 'error(s) found',
    customUploadFailed: 'custom data item(s) failed to add',
    addContainerError: 'Add Container Errors',
    removeHighlighted: 'Remove or edit the errors highlighted in the table below',
    reviewUploadContainerHeader: {
      action: 'Action',
      error: 'Error',
      containerNo: 'Container No. *',
      carrierNo: 'Carrier No.',
      BillOfLoading: 'Bill of Lading',
    },
    customDataError: 'Custom Data Errors',
    removeCustomHighlighted: 'Remove or edit the custom data errors highlighted in the table below',
    addContainerVia: 'Add {uploadType} via',
    blOrBn: 'Bill of Lading or Booking Number',
    contFindWhatYourLooking: 'Can\'t find what you\'re looking for?',
    co2Intensity: 'Intensity {intensity} kg/t.km',
    co2Message: 'Calculated on arrival at POD',
    noMasterIndexed: 'No Master Report Object Indexed',
    relatedShipment: 'Related Shipment',
    relatedShipments: 'Related Shipments',
    systemCreated: 'System created',
    emailWillBeSentShortly: 'Email will be sent shortly.',
    downloadWillBegin: 'Download will begin shortly.',
  },
  containerUploadLogsPage: {
    all: 'All',
    billOfLadingNo: 'Bill of Lading No.',
    carrierScac: 'Carrier SCAC',
    containerNo: 'Container No.',
    duplicates: 'Duplicates',
    error: 'Error',
    invalidContainers: 'Invalid Containers',
    invalidPayload: 'Invalid Payload',
    invalidScac: 'Invalid SCACs',
    noError: 'No Error',
    outOfQuota: 'Out Of Quota',
    recentUploads: 'Recent Uploads',
    search: 'Search for BOL, Container No., Carrier or Errors',
    title: 'Container Upload Logs',
    uploadedDatetime: 'Uploaded Datetime',
    validationStatus: 'Validation Status',
  },
  dashboardPage: {
    analytics: 'Analytics',
  },
  infiniteLoading: {
    noMore: 'No More Data Found',
    noResults: 'No More Records Found',
    spinner: 'Loading ...',
  },
  loginPage: {
    confirmNewPassword: 'Confirm New Password',
    confirmNewPasswordPlaceholder: 'Re-Enter New Password',
    emailInvalidError: 'Invalid Email',
    emailPlaceholder: 'Enter Registered Email',
    workEmailPlaceholder: 'Enter your work email',
    workEmailValidError: 'Please use your work email address to sign up.',
    emailRequiredError: 'Email is required',
    firstName: 'First Name',
    firstNameEnter: 'Enter First Name',
    forgotPassword: 'Forgot Password',
    lastName: 'Last Name',
    lastNameEnter: 'Enter Last Name',
    login: 'Login',
    newPassword: 'New Password',
    newPasswordEnter: 'Enter New Password',
    passwordDoNotMatch: 'Passwords don\'t match',
    passwordInvalidError: 'Invalid Password',
    passwordPlaceholder: 'Enter Password',
    passwordRequiredError: 'Password is required',
    passwordResetSuccess: 'Password Reset Successful ! Please Try Logging In',
    resetPassword: 'Reset Password',
    sendResetCode: 'Send Reset Code',
    title: 'Login into ',
    verificationCode: 'Verification Code',
    verificationCodeEnter: 'Enter Verification Code',
    verificationCodeRequired: 'Verification Code is required ',
    welcome: 'Welcome to ',
    signUp: 'Sign Up',
    signUpTitle: 'Create your Portcast account',
    signUpDescription: 'Enter your business email. Your account credentials and password will be sent to this email.',
    registeredSuccessfully: 'Registered successfully',
    signUpMessage: 'Account Creation Underway! You\'ll hear from us within 24 hours. In the meantime, check out our product video to get a feel for what we offer.',

  },

  portLookupPage: {
    code: 'Code',
    country: 'Country',
    countryCode: 'Country Code',
    latitude: 'Latitude',
    longitude: 'Longitude',
    ports: 'Ports',
    search: 'Search for Port Names or Port Codes',
  },
  profilePage: {
    masterOrg: 'Master Organization',
    noOfUsers: 'No. of Users',
    orgApiKey: 'Organization API Key',
    orgIdInfo:
            ' Include the Organization Id in the Email Upload Template. Download sample template below.',
    organisationApiKey: 'Organisation API Key',
    organizationId: 'Organization Id',
    organizationName: 'Organization Name',
    organizations: 'Organizations',
    quotaUtilization: 'Quota Utilization',
    searchOrganizations: 'Search Organizations',
    title: 'User Profile',
    userApiKey: 'User API Key',
    userDetails: 'User Details',
    userID: 'User ID',
    viewDetails: 'View Details',
    autoArchive: 'Auto Archive',
  },
  singleContainerPage: {
    billOfLadingNo: 'Bill of Lading No',
    copyUrl: 'Copy URL',
    destination: 'Destination',
    detailedEvents: 'Detailed Events',
    goBack: 'Go Back',
    incidents: 'Incidents',
    lastUpdated: 'Last Updated (UTC)',
    location: 'Location',
    mapView: 'Map View',
    modeOfTransport: 'Mode of Transport',
    ooops: 'Ooops!',
    predicted: 'PREDICTED',
    predictedActual: 'Predicted */Actual',
    resetZoom: 'Reset Zoom',
    somethingWentWrong: 'Something went wrong',
    source: 'Source',
    conversations: 'Conversations',
    noMessageFound: 'No conversations found for this record',
    messagePlaceholder: 'Use @ to tag people. An email will be sent to them ',
    noMessageFoundError: 'Error occurred while fetching conversations. Please try again.',
    fetchingMessages: 'Fetching conversations...',
    tryAgainLater: 'Please try again in sometime',
  },
  vesselsLookupPage: {
    breadthExtreme: 'Breadth Extreme',
    callSign: 'Call Sign',
    deadWeight: 'Dead Weight',
    draught: 'Draught',
    engineBuilder: 'Engine Builder',
    enginePower: 'Engine Power',
    engineStroke: 'Engine Stroke',
    grossTonnage: 'Gross Tonnage',
    search: 'Search for Vessels',
    speed: 'Speed',
    type: 'Type',
    vessels: 'Vessels',
  },
  v2: {
    sideNav: {
      addContainers: 'Add Containers',
      addContainer: 'Add Container',
      dashboard: 'Overview',
      tracking: 'Tracking',
      vesselTracking: 'Track Vessel',
      containerTracking: 'Track Container',
      portCongestion: 'Port Congestion',
      reports: 'Reports',

      developerTools: 'Dev Tools',
      containerLogs: 'Container Logs',
      vesselLogs: 'Vessel Logs',
      carrierLookup: 'Carrier Lookup',
      portLookup: 'Port Lookup',
      vesselLookup: 'Vessel Lookup',

      helpCenter: 'Support',
      knowledgeBase: 'Knowledge Base',
      reportAnIssue: 'Report an Issue',
      changeLog: 'Change Log',
      apiDocs: 'API Docs',

      profile: 'My Profile',
      logout: 'Logout',
    },
    singleContainerPage: {
      plannedArrival: 'Initially Scheduled',
      originalETA: 'Initially Scheduled',
      latestETA: 'Latest Scheduled',
      predictedETA: 'Latest Predicted ',
      downloadReport: 'Download Report',
      download: 'Download',
      shareURL: 'Share URL',
      share: 'Share',
      shareURLToolTip: 'Click here to copy URL',
      shareURLCopyMessage: 'URL Copied into clipboard',
      seeRelatedShipments: 'See Related Shipments',
      seeAllShipmentsOnVessel: 'See All Shipments on Vessel',
      noRelatedShipments: 'No other containers found under this B/L number',
      viewContainerDetails: 'View Detailed Events',
      blNo: 'Master BL Number',
      tabs: {
        detailedEvents: { title: 'Detailed Events' },
        productDetails: { title: 'Product Details' },
        customDetails: { title: 'Custom Details' },
        terminalDetails: { title: 'Terminal Details' },
        conversations: { title: 'Conversations' },
      },
      arrivalAtPOD: 'Arrival at POD',
      carrierArrival: 'Carrier Arrival',
      blNumber: 'Master BL Number',
      carrierLatestVessel: 'Carrier & Latest Vessel',
    },
    noLoginBanner: {
      title: 'Join Portcast to get',
      point1: 'All Carrier container tracking data in one place',
      point2: 'No more manual tracking for your teams',
      point3: 'Automated updates to your email and mobile',
      requestAccess: 'Request Access',
    },
    bulkUpload: {
      containerBulkUpload: 'Method 1: Add containers via file upload',
      containerManuallyUpload: 'Method 2: Add containers via manual input',
      bulkUploadText: 'Drop your files here or click here to add files',
      uploadSupportFile: 'We support .CSV, .XLS and .XlSX',
      downloadSampleUploadFile: 'Download sample upload file',
      addManuallyContainers: 'Click here to add containers manually',
      addingManuallyTakeMoreTime: 'You can upload multiple containers in one go',
      mapColumns: 'Map the columns in your uploaded file to the fields below',
      addContainersManually: 'Add Containers Manually',
    },
  },
  co2EmissionLockDialog: {
    title: 'CO2e Emissions Locked',
    description: 'CO2e emissions are currently locked for your account. Reach out to us to unlock the emission data.',
  },
  predictionsLockDialog: {
    title: 'Predictions Locked',
    description: 'Would you like to unlock the predictions for container visibility? Predictions are more accurate and refreshed every day. Reach out to us to unlock the predictions.',
  },
  emptyContainer: {
    NO_SEARCH_RESULTS: {
      title: 'Container Not Found',
      subTitle: 'No results found. Would you like to create a new container?',
    },
    CONTAINER_ERROR: {
      title: 'Container Error Found',
      subTitle: 'We found an error with the specified container. You can re-add it by clicking the button below.',
    },
    INVALID_INPUTS: {
      title: 'Container Error Found',
      subTitle: 'We found an error with the specified container. You can re-add it by clicking the button below.',
    },
    NO_FILTER_RESULTS: {
      title: 'Container Not Found',
      subTitle: 'No containers were found for the applied filters. Remove some filters and try again',
    },
    NO_RESULTS: {
      title: 'Container Not Found',
      subTitle: 'No containers were found for the applied filters. Remove some filters and try again',
    },
    containerAlreadyExists: 'Container already exists',
    invalidInputs: 'Invalid Inputs',
    addedSuccessfully: 'Container added successfully',
  },
  freightAudit: {
    invoiceType: 'Invoice Type',
    chargeType: 'Charge Type',
    container: 'Container',
    startDate: 'Start Date',
    endDate: 'End Date',
    occupancy: 'Occupancy ({type})',
    freeDays: 'Free Days ({type})',
    chargeableDays: 'Chargeable Days ({type})',
    uniCost: 'Unit Cost ({type})',
    unitQuantity: 'Unit Quantity ({type})',
    lineItem: 'Line Item Cost ({type})',
    delayByCarrier: 'Delay by carrier',
    noDelayCarrier: 'No carrier delay found for this invoice',
    finalCharge: 'Final Chargeable Days ({type})',
    occupancyDays: 'Occupancy Days ({type})',
    finalExtraDays: 'Final Extra Days ({type})',
    billInvoiceCost: 'Billed Invoice Costs',
    expectedInvoiceCost: 'Expected Invoice Cost',
    billInvoiceSubText: 'Billed costs are the charges that your carrier has invoiced you for. Items highlighted in red indicate discrepancies or potential errors in the billing',
    compareWithShipping: 'Compare with shipping, contract, and delay discrepancies',
    emptyGatePutDate: 'Empty Gateout Date',
    load: 'Load',
    recordGateOut: 'Recorded ETD at Empty Gateout',
    atd: 'ATD',
    recordVessel: 'Recorded Vessel at Empty Gateout',
    actualVessel: 'Actual Vessel',
    carrierDelaysPortcast: 'Carrier delays are calculated based on data from Portcast',
    shoppingAndContract: 'Shipping & Contract Discrepancy',
    shoppingAndContractSubText: 'These are the charges based on contractual rates and shipping data',
    disputableCost: 'Disputable Costs',
    dataAuditUnderway: 'Data Audit Underway',
    noShippingContract: 'No Shipping & Contract Discrepancy found for this invoice',
    billedInvoiceCost: 'Billed Invoice Cost ({type})',
    totalDisputableAmount: 'Total Disputable Amount (A-B)',
    disputableAmount: 'Disputable Amount',
    shippingAndContract: 'Shipping and Contract Discrepancy (Billed-Expected)',
    delayBasedDiscrepancy: 'Delay Based Discrepancy (Expected- Shipper Delay)',
    summaryInvoice: 'Summary of Billed and Expected Invoice Costs',
    summaryCostSubTitle: 'Billed costs are the charges that your carrier has invoiced you for. Expected costs are the charges based on contractual rates, shipping data, and carrier delays.',
    contractId: 'Contract ID',
    laneId: 'Lane ID',
    containerType: 'Container Type',
    OTHC: 'OTHC',
    DTHC: 'DTHC',
    BAF: 'BAF',
    freightRate: 'Freight Rate',
    penaltyCharge: 'Penalty Charge ({type})',
    exception: 'Exception',
    contractDate: 'Contract Data',
    contractDataSubText: 'These are the charges based on contractual rates and shipping data',
    contractDataNotFound: 'Contract Data Not Found',
    rawData: 'Raw Data',
    extractedDataTable: 'Extracted Data - Table',
    extractedDataJson: 'Extracted Data - JSON',
    standardizedData: 'Standardized Data',
    dataExtractionUnderway: 'Data Extraction Underway',
    containerId: 'Container ID',
    emptyGateOut: 'Empty Gate Out',
    fullGateIn: 'Full Gate In',
    LoadedOnVessel: 'Loaded on Vessel',
    ATD: 'ATD',
    ATA: 'ATA',
    unloadVessel: 'Unloaded from Vessel',
    fullGateOut: 'Full Gate Out',
    emptyReturn: 'Empty Return',
    emptyGateIn: 'Empty Gate In',
    etdAtPickUp: 'ETD at Pick Up (Recorded Time)',
    pickUpEvent: 'Pick Up Event (Recorded Time)',
    vesselAtPickUp: 'Vessel # at Pick Up',
    vesselAtAtd: 'Vessel # at ATD',
    shippingData: 'Shipping Data',
    shippingDataSubText: 'Shipping data is sourced from the carrier and shows the latest timestamps',
    shippingDataNotFound: 'Shipping Data Not Found',
    eventStartOn: 'Event Starts on',
    eventStartDate: 'Event Start Date',
    eventEndOn: 'Event Ends on',
    eventENDDate: 'Event End Date',
    freeDay: 'Free Days',
    unitQuantityData: 'Unit Quantity',
    unitCost: 'Unit Cost',
    unitCostCurrencyType: 'Unit Cost Currency Type',
    unitType: 'Unit Type',
    chargeableDaysData: 'Chargeable Days',
    totalLineCost: 'Total Line Cost',
    lineCostCurrencyType: 'Line Cost Currency Type',
    exchangeRate: 'Exchange Rate',
    tax: 'Tax',
    addInvoice: 'Add Invoice',
    weSupportPdf: 'We support PDF Files',
    additionalChargeDetected: 'Additional Charges Detected',
    underChargesDetected: 'Undercharges Detected',
    wronglyChanged: 'You were wrongly charged {percentage}% more than you should have been',
    youAreUnderCharged: 'You are under charged less than you should have been',
    all: 'All',
    allTabSubTitle: 'All - Invoices that are actively tracked',
    invoiceAmount: 'Invoice Amount',
    totalBillAmount: 'Total Billed Amount',
    currency: 'Currency',
    disputeBy: 'Dispute By',
    disputeAmountNotCalculated: 'Disputable Amount: Not calculated',
    reason: 'Reason',
    invoiceDueDate: 'Invoice Due Date',
    dueDate: 'Due Date',
    billedDate: 'Billed Date',
    shipmentNumber: 'Shipment Number',
    notCalculated: 'Not calculated',
    invoiceData: 'Invoice Data',
    auditData: 'Audit Data',
    hideInvoice: 'Hide Invoice',
    dataMatchingUnderWay: 'Data Matching Underway',
    disputeSummary: 'Dispute Summary',
    noAdditionalCharge: 'No Additional Charges Detected',
    youAreWronglyCharged: 'You were wrongly charged because of',
    youAreCorrectlyCharged: 'You were correctly charged. All the charges on the invoice match the contract and shipping data',
    raiseDispute: 'Raise Dispute',
    raiseDisputeMessage: 'To initiate the dispute, you need to download the relevant evidence. This evidence will support your case and is necessary for the next steps.',
    downloadEvidence: 'Download Evidence',
    emailBody: 'Hello,\n\nInvoice #{identifier} contains incorrect charges applied. Attached are documents supporting the dispute claim. Please review and make adjustments to correct the amount, thank you.',
    disputeRaisedSuccessfully: 'Dispute Raised Successfully',
    disputeWithComments: 'Dispute raised with comments',
    raiseDisputeNow: 'Raise Dispute Now',
    addAnInternalNoteHere: 'Add an internal note here',
    raiseDisputeViaEmail: 'Raise Dispute Via Email',
    copyEmailTemplate: 'Copy Email Template',
  },
  airCargo: {
    tabs: {
      detailedEvents: 'Detailed Events',
      flightInfo: 'Flight Info',
      productDetails: 'Product Details',
    },
    arrivalAtDestination: 'Arrival at Destination',
    estimated: 'Estimated',
    piecesWeights: 'Pieces & Weights',
    totalPieces: 'Total pieces',
    totalWeight: 'Total weight',
    carrierDetails: 'Carrier Details',
  },
}
