export const ja = {
  'carrierLookupPage': {
    carriers: 'キャリア',
    fullName: 'フルネーム',
    search: 'キャリアまたはSCACを検索',
    synonyms: '類義語',
  },
  'common': {
    showJson: 'Json を表示',
    carrierTime: 'キャリアタイム',
    dashboard: 'ダッシュボード',
    requestReceived: 'リクエスト受信',
    viewTransitTimeDashboard: 'トランジットタイムの履歴表示',
    requestReceivedMessage: 'ご連絡ありがとうございます。48時間以内にご返信いたします。',
    noThanks: 'いいえ、結構です。',
    contactUs: 'お問い合わせ',
    contactUsToUnlock: 'ロック解除をご希望の際はお問い合わせください',
    dataUnavailable: 'データがありません',
    co2Emission: 'CO2e排出量',
    emission: '排出量',
    locked: 'ロックされています',
    predictionLocked: '予測機能がロックされています',
    preferences: '環境設定',
    enableNewUI: '新しいUIを有効にする',
    iAccept: '私は以下の',
    termsOfUse: '利用規約に同意します',
    openInNewWindow: '新しいウィンドウで開く',
    unavailable: '利用できません',
    availableSoon: '近日公開予定',
    send: '送信',
    portCongestion: '港の混雑状況',
    developerTooling: '開発者ツール',
    activeOrg: 'アクティブ中の組織',
    actual_arrival: '実際の到着日',
    added_on: '追加日',
    anchorageStay: 'アンカレッジ滞在',
    apiDocumentation: 'APIドキュメント',
    archive: 'アーカイブ',
    archived: 'アーカイブ済み',
    arrival_status: '到着状況',
    arrive_at_pod: 'PODに到着',
    arrive_at_pot: 'POTに到着',
    arrived: '到着済み',
    arriving: '到着中',
    ts_waiting_time_in_days: 'TS待ち時間(日)',
    is_transhipment: '到着中',
    bls: 'BLS',
    booking_no_: '予約番号',
    byCarrier: 'キャリア別',
    cancel: 'キャンセル',
    carriers: 'キャリア',
    confirm: '確認',
    confirmMessage: '本当に進めてもよろしいですか？',
    consignee: '受取人',
    container: 'コンテナ',
    containerNotMappedWithBL: 'コンテナがB/Lにマッピングされていません',
    containerNotTrackedByCarrier: 'コンテナがキャリアにより追跡されていません',
    containers: 'コンテナ',
    contents: '内容',
    copied: 'コピーされました',
    copy: 'コピー',
    count: '数量',
    days: '日数',
    delay_in_days: '遅延日数',
    delete: '削除',
    depart_at_pol: 'POL発',
    depart_at_pot: 'POT発',
    direct: '直行',
    discharge: '荷降ろし',
    discharged: '荷降ろし済み',
    done: '完了',
    download: 'ダウンロード',
    downloadAll: 'すべてダウンロード',
    early: '早期',
    earlyJourney: '早期配送',
    edit: '編集',
    email: 'メール',
    emptyReturn: '空返送',
    error: 'エラー',
    errors: 'エラー',
    events: 'イベント',
    exceptions: 'コントロールダッシュボード',
    from: 'ゲートアウト',
    gateOut: 'ゲートアウト',
    gatein_at_pol: 'POLでゲートイン',
    gateout_at_pod: 'PODでゲートアウト',
    gateout_at_pol: 'POLでゲートアウト',
    importer: '輸入業者',
    inThis: 'この中から',
    incidentList: {
      anchorageStay: 'アンカレッジ滞在',
      nonOptimalRoute: '最適化されていないルート',
      outdatedSchedule: '過去のスケジュール',
      portStay: '港滞在',
    },
    helpCenter: 'ヘルプセンター',
    incidents: 'インシデント',
    init: '初期化',
    internal_ref_no_: '内部参照番号',
    late: '遅延',
    load_at_pol: 'POLで荷積み',
    load_at_pot: 'POTで荷積み',
    loading: '読み込み中...',
    logout: 'ログアウト',
    logs: 'ログ',
    lookup: '検索',
    new: '新規',
    noDataFromCarrier: 'キャリアからのデータなし',
    nonOptimalRoute: '最適化されていないルート',
    of: 'の',
    okay: 'OK',
    onTime: '予定通り',
    others: 'その他',
    or: 'または',
    organization: '組織',
    outdatedSchedule: '過去のスケジュール',
    p_o_no_: 'PO番号',
    password: 'パスワード',
    pod: 'POD',
    pol: 'POL',
    pol_waiting_time_in_days: 'POL 待ち時間（日',
    portNotInSchedule: 'スケジュール外の港',
    portStay: '港滞在',
    ports: '港滞在',
    predicted_arrival: '到着予想',
    proceed: '進む',
    refreshDataConfirmMessage: '{total}個のコンテナデータを更新してもよろしいですか？',
    reindexDataConfirmMessage: 'データを再インデックスしてもよろしいですか？',
    releaseNotes: 'サポートデスク',
    return_at_pod: 'PODで返送',
    save: '保存',
    searchOrgs: '組織を検索',
    selected: '選択済み',
    showAll: 'すべて表示',
    status: 'ステータス',
    statusList: {
      'Arrived': '到着済み',
      'NO_DATA_RECEIVED_FROM_CARRIER': 'キャリアのデータがありません',
      'BL_NOT_FOUND': 'B/Lが見つかりません',
      'BULK_INIT': '予測の準備中',
      'CONTAINER_IDLE_AFTER_GATEOUT': 'ゲートアウト後コンテナ停止中',
      'CONTAINER_NOT_FOUND': 'コンテナが見つかりません',
      'CONTAINER_NOT_MAPPED_WITH_BL': 'コンテナがB/Lにマッピングされていません',
      'CONTAINER_RETURNED_TO_CARRIER': 'コンテナがキャリアに返却されました',
      'INIT': '予測の準備中',
      'NO_DATA_FROM_PROVIDER': 'キャリアのデータがありません',
      'On Time': '予定通り',
      'Preparing Predictions': '予測の準備中',
      'SUCCESS': '成功',
      'SUCCESS_EARLY_JOURNEY': '早期配送',
      'EARLY_JOURNEY': '早期配送',
      'TARGET_PORT_NOT_IN_ROTATION': 'スケジュール外の港',
      'earlyByXDays': '{days}日早く到着予定',
      'lateByXDays': '{days}日遅れで到着予定',
      'delayByDays': '{days}日の遅延',
    },
    status_code: 'ステータスコード',
    stop: '停留所',
    stops: '停留所',
    supplier: 'サプライヤー',
    switchActiveOrg: 'アクティブ中の組織を切り替え',
    switchTab: '今すぐ別のタブに切り替えてください',
    t_m_s_ref_no_: 'TMS参照番号',
    t_s: 'T/S',
    thereAreNo: 'ありません',
    unique: 'ユニーク',
    unknownError: '不明なエラー',
    unload_at_pod: 'PODで荷降ろし',
    unload_at_pot: 'POTで荷降ろし',
    updatedSuccessfully: '更新成功',
    updated_on: '更新日',
    uploadLogs: 'ログのアップロード',
    vessel: '船舶',
    vesselTracking: '船舶追跡',
    vessels: '船舶',
    viewProfile: 'プロフィールを表示',
    makeAsDefault: 'デフォルトとして設定',
    containerNumber: 'コンテナ番号',
    billNumber: 'B/L番号',
    organisation: '組織',
    addedUpdated: '追加・更新済み',
    exceptionsIncidents: '例外とインシデント',
    poNumber: 'PO番号',
    tmsRefNumber: 'TMS参照番号',
    content: '内容',
    tableView: 'テーブルビュー',
    calenderView: 'カレンダービュー',
    mapView: 'マップビュー',
    customLate: 'カスタム遅延',
    viewName: 'ビュー名',
    viewDescription: 'ビューの概要',
    optional: '任意',
    setAsDefaultView: 'デフォルトビューとして設定',
    portOfLoading: '積荷港',
    transhipment: 'トランシップメント',
    transhipments: 'トランシップメント',
    portOfDeparture: '陸揚げ港',
    search: '検索...',
    routeChange: 'ルート変更',
    anchorageDelay: 'アンカレッジ遅延',
    portSequenceChange: '港の順序変更',
    carrierConsideringDelay: 'キャリア遅延予定',
    weatherAdversity: '悪天候',
    delayAtUpcomingPort: '次の港での遅延',
    carrierDataMissing: 'キャリアのデータ欠損',
    invalidInput: '入力が無効です',
    remove: '削除',
    filters: 'フィルター',
    loadingPleaseWait: '読み込み中... お待ちください',
    original: 'オリジナル',
    portcast: 'Portcast',
    clearAll: 'すべてクリア',
    active: 'アクティブ',
    bookmarked: 'ブックマーク済み',
    bookmark: 'ブックマーク',
    filterAllMessage: 'すべて：アクティブ、アーカイブ、エラーを含むすべてのコンテナ。',
    filterActiveMessage: 'アクティブ： 現在追跡されているコンテナ。このリストには、アーカイブやエラーのコンテナは含まれません。',
    filterNewMessage: '新規： 過去24時間以内にアップロードされたコンテナ。',
    filterLateMessage: '遅延：予定到着時間を過ぎて到着予定のアクティブなコンテナ。',
    filterArrivedMessage: '到着済み： PODに到着したコンテナ。',
    filterBookmarkedMessage: 'ブックマーク済み： この組織のユーザーがブックマークしたコンテナ。',
    selectAllContainers: '{count}個のすべてのコンテナを選択',
    scheduleAlertMessage: 'コンテナに関する通知を設定しますか？コンテナの重要なマイルストーンに関する通知をEメールまたは携帯電話番号にお届けします。',
    scheduleAlerts: 'アラートを設定する',
    dontSchedule: '設定しない',
    bookmarkContainerMessage: 'コンテナをブックマークしますか？重要なコンテナを優先して追跡するのに役立ちます。',
    bookmarkContainers: 'コンテナをブックマークする',
    dontBookmark: 'ブックマークしない',
    downloadContainerMessage: 'コンテナをダウンロードしますか？',
    downloadVesselMessage: '船舶をダウンロードしますか？',
    download300ContainerMessage: '選択したコンテナをダウンロードしますか？300以上のコンテナを選択すると、処理に時間がかかる場合があります。',
    download300VesselMessage: '選択した船舶をダウンロードしますか？300以上の船舶を選択すると、処理に時間がかかる場合があります。',
    dontDownload: 'キャンセル',
    shareViaEmail: 'メールで共有',
    downloadNow: '今すぐダウンロード',
    archiveContainerMessage: 'コンテナをアーカイブしますか？選択したコンテナの追跡を停止します。',
    archiveContainer: 'コンテナをアーカイブ',
    dontArchive: 'アーカイブしない',
    deleteContainerMessage: '永久に削除されます。コンテナがリストから削除されます。',
    deleteVesselsMessage: '永久に削除されます。船舶がリストから削除されます。',
    deleteContainer: 'コンテナを削除',
    deleteVessel: '船舶を削除',
    dontDelete: '削除しない',
    eta: '到着予定日',
    carrierVessel: 'キャリア/船舶',
    action: 'アクション',
    searchByContainer: '検索',
    searchByPodPolTs: 'POL、POD、またはT/Sで検索',
    searchByVesselCarrier: '船舶または運送業者から探す',
    searchByCustomer: '顧客から探す',
    searchByVessel: '船舶番号またはB/L番号で検索',
    applyGroupBy: 'グループ化を適用',
    applySort: '並び替えを適用',
    showContainers: 'コンテナを表示',
    switchOrganisation: '組織を切り替える',
    showCountContainers: '{count} 個のコンテナを表示',
    showCountVessels: '{count} 個の船舶を表示',
    applyFilters: 'フィルターを適用',
    saveView: 'ビューを保存',
    savedViews: '保存されたビュー',
    selectView: 'ビューを選択',
    addNew: '新規追加',
    chooseView: 'ビューを選択',
    applyView: 'ビューを適用',
    asDefault: 'デフォルトとして',
    set: '設定',
    viewNameError: 'ビュー名は既に存在します',
    lateByDays: '{start}から{end}日遅延',
    lateByDaysNumber: '{count}日以上遅延',
    latestEvents: '最新のイベント',
    masterOrg: 'マスター組織',
    emptyGateOutAtPOL: '空のゲートアウト(POLで)',
    gateInFullAtPOL: 'フルコンテナでゲートイン\n(POL港)',
    loadedAtPOL: '荷積み済み(POL港)',
    departedFromPOL: '出発済み(POL港)',
    arrivalAtPOD: '到着(POD港)',
    dischargedAtPOD: '荷降ろし済み(POD港)',
    gateOutFullAtPOD: 'フルコンテナでゲートアウト(POD港)',
    emptyReturnAtPOD: '空返送(POD港)',
    lateStatus: '遅延状況',
    created: '作成済み',
    updated: '更新済み',
    aisSatellite: 'AIS衛星データ',
    containerNotFound: 'コンテナが見つかりません。<br>いくつかのフィルターを外してみてください。',
    vesselsNotFound: '船舶が見つかりません。<br>いくつかのフィルターを外してみてください。',
    comingSoon: '近日公開予定',
    pleaseTryAgain: '後ほど再度ご確認の上、詳細をお調べしますのでご報告ください。',
    port: '港',
    country: '国',
    pol_country: 'POL国',
    pod_country: 'POD国',
    clickToCopy: 'クリックしてコピー',
    clickToCopyContainer: 'クリックしてコンテナ番号をコピー',
    generatingPrediction: '予測を生成中',
    latest: '最新',
    dontHaveAnAccount: 'まだアカウントをお持ちではないですか？',
    alreadyHaveAnAccount: '既にアカウントをお持ちですか？',
    corporateEmailInvalidError: 'サインアップには勤務先のメールアドレスをご利用ください。',
    noOrgDialogTitle: '管理者に問い合わせる',
    noOrgDialogDescription: '組織が割り当てられていません。管理者にご連絡ください。',
    thankYou: 'ありがとうございます',
    needsAttention: '要注意',
    onTheWay: '途中経過',
    targetPort: '対象港',
    targetPortEta: '対象港のETA',
    PredictedActual: '予測 * / 実績',
    scheduledArrival: '到着予定',
    errorCode: 'エラーコード',
    flaggedOn: 'フラグ日',
    showVessels: '船舶を表示',
    selectAllVessels: '{count}個すべての船舶を選択',
    restart: '再起動',
    reIndexContainers: 'コンテナデータを再インデックス',
    reIndexMessage: 'コンテナデータの再インデックスを実行しますか？',
    refresh: '更新',
    refreshVessels: '船舶情報を更新',
    refreshVesselsMessage: '選択した{count}個の船舶データを更新しますか？',
    downloadSuccess: 'コンテナデータが正常にダウンロードされました。',
    somethingWentWrong: '何らかの問題が発生しました。後でもう一度試してください。',
    uploadingLimitExceed: 'アップロード制限を超えました',
    containerUploadedSuccessfully: 'コンテナデータが正常にアップロードされました',
    reportType: 'レポートの種類',
    timeRange: '期間',
    selectFields: 'フィールドを選択',
    departingContainers: '出発予定のコンテナ',
    departedContainers: '出発済みのコンテナ',
    arrivingContainers: '到着予定のコンテナ',
    arrivedContainers: '到着済みのコンテナ',
  },
  'containerPage': {
    actual: '実際',
    actualArrivalAscending: '実際の到着日 - 昇順',
    actualArrivalDescending: '実際の到着日 - 降順',
    addContainer: 'コンテナを追加',
    addContainers: 'コンテナを追加する',
    addNewContainers: '新しいコンテナを追加する',
    addMoreContainers: 'コンテナを更に追加する',
    addedOn: '追加日',
    addedOnMasterOrg: '組織 / 追加日',
    addedOnSortByItems: {
      created_asc: '作成日 - 昇順',
      created_desc: '作成日 - 降順',
    },
    allTab: 'すべて',
    archiveContainers: 'コンテナをアーカイブ',
    archivedTab: 'アーカイブ済み',
    arrival: '到着',
    arrivalSortByItems: {
      pod_ais_actual_arrival_asc: '実際の到着日 - 昇順',
      pod_ais_actual_arrival_desc: '実際の到着日 - 降順',
      pod_portcast_predicted_arrival_asc: '到着予定日 - 昇順',
      pod_portcast_predicted_arrival_desc: '到着予定日 - 降順',
    },
    arrivalStatus: '到着状況',
    arrivedAtPOD: 'PODに到着',
    arrivedAtTS: '中継地(T/S)に到着',
    arrivedTab: '到着済み',
    arrivingTab: '到着中',
    billOfLading: 'B/L',
    bookingInfo: '予約情報',
    bookingInformation: '予約情報',
    bookingNo: '予約No.',
    bookingNumber: '予約番号',
    browseFiles: 'ファイルを参照',
    bulkUpload: '一括アップロード',
    carrier: 'キャリア',
    carrierNo: 'キャリア番号',
    carrierSCAC: 'キャリア / SCAC',
    carrierSCACVessels: 'キャリア SCAC / 船舶',
    clear: 'クリア',
    clearAppliedFilters: '適用されたフィルタをクリア',
    consignee: '受取人',
    containerArchiveSuccessful: '{count}個のコンテナを正常にアーカイブしました！',
    containerEvent: 'コンテナイベント',
    containerNo: 'コンテナ番号',
    containerNoBl: 'コンテナ番号 / B/L',
    containerNotMappedWithBL: 'コンテナがB/Lにマッピングされていません',
    containerNotTrackedByCarrier: 'コンテナがキャリアにより追跡されていません',
    containerUploadErrorDuplicate: '重複アップロードの試行',
    containerUploadErrorInvalidContainer: '無効なコンテナ番号',
    containerUploadErrorInvalidCarrier: '無効なキャリア番号',
    containerUploadErrorQuotaExceeded: '割り当て超過',
    containerUploadErrorMissingCarrier: 'キャリア名不備',
    containerUploadErrorMissingContainer: 'コンテナ番号不備',
    contentBrand: '内容 / ブランド',
    contents: '内容',
    contractualOriginalETA: '契約上の/オリジナルETA（YYYY-MM-DD）',
    contractualOriginalETD: '契約書 / オリジナルETD (YYYY-MM-DD)',
    customer: '顧客',
    deletedAllInvalidText: '無効なコンテナをすべて削除しました',
    departedFromPOL: 'POL出発',
    departedFromTS: '中継地(T/S)出発',
    departure: '出発地',
    destination: '目的地',
    discharge: '荷降ろし',
    dischargedAtPOD: 'PODで荷降ろし済み',
    dischargedAtTS: '中継地(T/S)で荷降ろし済み',
    disclaimer: 'キャリアの当初の予定日に基づく',
    downloadAll: '全てのコンテナをダウンロード',
    downloadContainers: 'コンテナをダウンロード',
    downloadSample: 'サンプルCSVをダウンロード',
    downloadSelected: '選択したコンテナをダウンロード',
    dropFilesHere: 'ここにファイルをドロップ',
    earlyJourney: '早期配送',
    earlyTab: '早期',
    emptyReturn: '空返送',
    enterData: 'ここにデータを入力',
    errorCodeFlaggedByTab: 'エラーコード / 識別者',
    exportErrorCSV: 'エラーをCSVでエクスポート',
    fileSupport: '.CSV、.XLS、.XlSXに対応しています',
    filterBookingNo: '予約番号でフィルタ',
    filterByBLs: 'B/Lでフィルタ',
    filterByCarriers: 'キャリア名でフィルタ',
    filterByContainerNos: 'コンテナ番号でフィルタ',
    filterByPODPorts: 'POD港でフィルタ',
    filterByPOLPorts: 'POL港でフィルタ',
    filterByTSPorts: 'TS港でフィルタ',
    filterByVessels: '船舶名でフィルター',
    filterConsignee: '荷受人名でフィルタ',
    filterContents: '内容物別でフィルタ',
    filterImporter: '輸入者名でフィルタ',
    filterInternalRef: '内部参照番号でフィルタ',
    filterPoNo: '注文番号(PO番号)でフィルタ',
    filterSupplier: 'サプライヤー名でフィルタ',
    filterTmsRef: 'T.M.S参照番号でフィルタ',
    finalVesselName: '最終船舶名',
    firstVesselName: '第1船舶名',
    flaggedOnTab: 'フラグ日',
    gateInFull: 'フルコンテナでゲートイン',
    gateOutEmpty: '空コンテナでゲートアウト',
    gateOutFull: 'フルコンテナでゲートアウト',
    generateNewPredictions: {
      disabledTitle: '到着したコンテナに対して予測を生成することはできません。',
      subTitle: 'コンテナの最新ETAを取得する',
      successMessage: '新しい予測を生成しています。1〜2分後に再度ご確認ください。',
      title: '新しい予測を生成する',
    },
    groupBy: 'グループ分け',
    groupingByUnique: '固有のグループ化',
    importer: '輸入者',
    initiallyScheduled: '初期スケジュール',
    plannedArrival: '初期スケジュール',
    planned: '初期',
    internalRef: '内部参照',
    internalRefNumber: '内部参照番号',
    invalidContainerNo: '無効なコンテナ番号',
    journey: '旅程',
    lateTab: '遅延',
    latestEvent: '最新イベント',
    latestPredicted: '最新(予測)',
    latestScheduled: '最新スケジュール',
    loadedAtPOL: 'POLで荷積み済み',
    loadedAtTS: '中継地(T/S)で荷積み済み',
    loading: '荷積み中',
    mapColumns: 'マップ列',
    mapColumnsHeader: '選択された行に列のヘッダーが含まれていますか？',
    mapColumnsTip: 'ファイルから対応する列を選択してください',
    miscellaneousInformation: 'その他の情報',
    newTab: '新規',
    nextStepsInstruction: '次のステップでは、スプレッドシートの列を正しいデータポイントに一致するよう調整できます。',
    noDataFromCarrier: 'キャリアのデータがありません',
    noInvalidContainers: '無効なコンテナはありません。',
    noSailingInfo: '航海情報なし',
    outboundHaulage: 'アウトバウンド輸送',
    outboundHaulageID: 'アウトバウンド輸送ID',
    packageQuantity: 'パッケージ数量',
    poNo: 'PO No.',
    poNumber: 'PO番号',
    pod: 'POD',
    pol: 'POL',
    portNotinSchedule: 'スケジュール外の港',
    predicted: '予測',
    predictedActualArrival: '予想/実際の到着日',
    predictedArrivalAscending: '予測到着日 - 昇順',
    predictedArrivalDescending: '予測到着日 - 降順',
    preparingPredictions: '予測の準備中',
    productDescription: '商品説明',
    productDetails: '商品詳細',
    productInfo: '商品情報',
    productInformation: '商品情報',
    redFlagComments: 'コメント、その他追加したい内容を記入してください',
    redFlagContainer: 'コンテナにレッドフラグ（赤旗）を立てる',
    redFlaggedSuccessfulMessage: '{count}個のコンテナを正常に識別しました。レッドフラグが立っているタブでも確認できます',
    redFlaggedTab: 'レッドフラグ済み',
    refreshOrgData: 'データを更新',
    reindexData: 'データを再インデックス',
    reviewContainers: 'コンテナをレビュー',
    sailingDates: '出航日',
    sailingInfo: '航海情報',
    sailingInfoNotAvailable: 'POLまたはPOD情報が欠損しているため、航海情報が利用できません。',
    saleInfo: '販売情報',
    salesOrder: '販売注文',
    scheduled: '予定',
    searchContainers: 'コンテナ検索',
    selectAnotherRow: '別の行を選択',
    selectReasonForRedFlag: 'フラグを立てる理由を選択',
    showEtaHistory: 'ETA履歴を表示',
    sortBy: '並び替え',
    sortByAddedOnDate: '追加日順',
    sortByArrivalDate: '到着日順',
    source: 'ソース',
    sourceFile: 'ソースファイル',
    status: 'ステータス',
    statusIncidents: 'ステータス / インシデント',
    subtextAddContainers: 'ソースファイルから複数の行をコピーして、ここに貼り付けることができます。',
    success: '成功',
    supplier: 'サプライヤー',
    supplierID: 'サプライヤーID',
    title: 'コンテナ追跡',
    tmsRef: 'TMS参照番号',
    tmsReferenceNumber: 'TMS参照番号',
    ts: '中継地(T/S)',
    unflagContainers: 'コンテナのフラグ解除',
    updatedOn: '更新日',
    uploadAddContainers: 'コンテナのアップロード/追加',
    uploadCompleted: 'アップロード完了',
    uploadFailed: 'アップロード失敗',
    uploadFailedMessage: 'コンテナのアップロードに失敗しました。',
    uploadMessage1: 'アップロードしました',
    uploadMessage2: 'コンテナのアップロードに成功しました',
    vessel: '船舶',
    vesselNotConfirmed: '船舶はまだ確認されていません',
    vesselNotConfirmedTooltip: 'キャリアは船名をまだ確認していません。48時間以内に再度ご確認ください。',
    vesselVoyage: '船舶、航海',
    viewRecentUploadLogs: '最近アップロードされたコンテナログを表示',
    search: '検索',
    origin: '原産地',
    selectPort: '港を選択',
    selectCountry: '国を選択',
    addingContainerInProgress: 'コンテナ追加中...',
    AddCountContainers: '{count}個のコンテナを追加する',
    new: '新規',
    createReport: 'レポート作成',
  },
  'containerUploadLogsPage': {
    all: '全て',
    billOfLadingNo: 'B/L番号',
    carrierScac: 'キャリアSCAC',
    containerNo: 'コンテナ番号',
    duplicates: '重複',
    error: 'エラー',
    invalidContainers: '無効なコンテナ',
    invalidPayload: '無効なペイロード',
    invalidScac: '無効なSCAC',
    noError: 'エラーなし',
    outOfQuota: '割り当て量超過',
    recentUploads: '最近のアップロード',
    search: 'BOL、コンテナ番号、キャリア、エラーを検索',
    title: 'コンテナアップロードログ',
    uploadedDatetime: 'アップロード日時',
    validationStatus: '検証ステータス',
  },
  'dashboardPage': {
    containersAtPOD: 'PODのコンテナ',
    containersAtPODToolTip: 'PODのコンテナのマイルストーン。特定のマイルストーンをクリアしたコンテナを特定するには、このウィジェットを使用します。',
    containersAtTS: 'POTでの滞在時間',
    containersAtTSToolTip: 'アクティブのコンテナのPOT滞在期間。POTでの荷役イベントと荷卸しイベントの差に基づいて計算されます。10日以上のバケットにあるコンテナは、ロールオーバーのリスクがあるためご注意ください。',
    delay10OrMoreDays: '10日以上',
    delay1To4Days: '待ち時間 1-4日',
    delay5To9Days: 45055,
    delayedContainers: '遅延コンテナ',
    delayedContainersToolTip: '早く到着したコンテナ、時間通りに到着したコンテナ、遅れて到着したコンテナの分布',
    departureDelayAtPOL: 'POLでの滞在時間',
    departureDelayAtPOLToolTip: 'アクティブ中のコンテナのPOL滞在期間。POLでの荷積みイベントとフルコンテナのゲートインイベントの差に基づいて計算されます。10日以上のバケットにあるコンテナは、D&Dチャージが発生する可能性がありますのでご注意ください。',
    incidentToolTip: 'インシデントが発生したコンテナの分布',
    late1To2Days: '遅延：1-2日',
    late3To5Days: '遅延：3-5日',
    late5OrMoreDays: '遅延：5日以上',
    problematicContainers: '問題のあるコンテナ',
    problematicContainersToolTip: '以下の問題があるコンテナの分布。キャリアによって追跡されていないコンテナ、キャリアデータがないコンテナ、BLにマッピングされていないコンテナ、予定より早く輸送されたコンテナ、スケジュール外の港のコンテナ',
    title: 'コントロールダッシュボード',
    topTradeLanesUsed: '最も利用されている貿易レーン',
    topTradeLanesUsedToolTip: '固有のポートペアから出発するコンテナ数',
    totalBLs: 'BL総数',
    totalBLsToolTip: 'Portcastが現在追跡しているユニークBL数',
    totalContainers: 'コンテナ総数',
    totalContainersToolTip: '現在Portcastが追跡しているコンテナ数',
    totalContainersToolTipItalics: '遅延（海上）＋到着（POD）＋問題あり',
    totalVesselVoyages: '合計船舶/航海',
    totalVesselVoyagesToolTip: '現在Portcastが追跡している固有船舶/航海',
    waiting10OrMoreDays: '10日以上',
    waiting5To10Days: '5-10日',
    waitingTime1To3Days: '待ち時間 1-3日',
    overview: '分析',
    exceptions: '例外',
    analytics: '分析',
    viewAll: 'すべて見る',
    breakdownLateContainers: '遅延コンテナの内訳',
    totalTracked: '総追跡数',
    active: 'アクティブ',
    activeTooltip: 'Portcastが現在追跡しているコンテナのサブセット',
    withError: 'エラーあり',
    withErrorTooltip: 'エラーにより追跡データが欠落しているコンテナのサブセット',
    inactive: '非アクティブ/アーカイブ',
    inactiveTooltip: '追跡が終了したコンテナのサブセット。手動またはプログラムによってアーカイブされたコンテナ（最後のコンテナイベントのマイルストーンが取得されてから30日経過したコンテナなど）が含まれます。',
    totalActive: '総アクティブ数',
    journeyTBC: '早期配送',
    journeyTBCTooltip: 'これらのコンテナは輸送の初期段階であり、海上トラッキングデータが利用できません。',
    arrivedAtPOD: 'POD到着',
    arrivedAtPODTooltip: 'コンテナがPODに無事到着しました。',
    journeyInProgress: '海上輸送中',
    journeyInProgressTooltip: 'コンテナは現在PODへ輸送中です。',
    withErrors: 'エラーあり',
    carrierDataMissing: 'キャリアデータがありません',
    carrierDataMissingTooltip: 'キャリアがデータを反映していません。輸送の初期段階であるか、すでに期限切れになっている可能性があります： Portcastが新規または更新データを確認します。24時間以内に再度ご確認ください。',
    invalidInput: '入力が無効です',
    invalidInputTooltip: 'タイプミスの可能性があります。以下のコンテナは、入力されたBL/予約番号に該当しないようです。次のステップ：正しいコンテナ番号、BL/予約番号が入力されているか再度ご確認ください。',
    unknownError: '不明なエラー',
    unknownErrorTooltip: 'コンテナを処理する際に内部エラーが発生しました。次のステップ：現在調査中です。12時間以内に再度ご確認ください。',
    commonReasonsForDelay: '一般的な遅延理由',
    portStay: '港滞在',
    portStayTooltip: '本インシデントは、船舶/コンテナが港に到着する前に、港湾区域での待機時間が延長した場合に表示されます。',
    anchorageStay: 'アンカレッジ滞在',
    anchorageStayTooltip: '本船/コンテナが港に到着する前に、停泊地でさらに時間を要した場合に表示されます。',
    nonOptimalRoute: '最適化されていないルート',
    nonOptimalRouteTooltip: '本インシデントは、船舶が最適化されたルートに従っていない場合に表示されます。',
  },
  'infiniteLoading': {
    noMore: 'その他のデータが見つかりません',
    noResults: 'その他の履歴は見つかりません',
    spinner: '読み込み中 ...',
  },
  'loginPage': {
    confirmNewPassword: '新しいパスワードの確認',
    confirmNewPasswordPlaceholder: '新しいパスワードを再入力',
    emailInvalidError: '無効なメールアドレス',
    emailPlaceholder: '登録済みのメールアドレスを入力してください',
    workEmailPlaceholder: '勤務先メールアドレスを入力',
    workEmailValidError: 'ご登録には勤務先のメールアドレスをご使用ください。',
    emailRequiredError: 'メールアドレスは必須項目です',
    firstName: '名',
    firstNameEnter: '名前を入力してください',
    forgotPassword: 'パスワードを忘れた場合',
    lastName: '姓',
    lastNameEnter: '姓を入力してください',
    login: 'ログイン',
    newPassword: '新しいパスワード',
    newPasswordEnter: '新しいパスワードを入力',
    passwordDoNotMatch: 'パスワードが一致しません',
    passwordInvalidError: 'パスワードが無効です',
    passwordPlaceholder: 'パスワードを入力',
    passwordRequiredError: 'パスワードは必須です',
    passwordResetSuccess: 'パスワードのリセットに成功しました！再度ログインをお試しください',
    resetPassword: 'パスワードをリセット',
    sendResetCode: 'リセットコードを送信',
    title: 'ログイン',
    verificationCode: '認証コード',
    verificationCodeEnter: '認証コードを入力してください',
    verificationCodeRequired: '認証コードは必須です',
    welcome: 'ようこそ',
    signUp: 'サインアップ',
    signUpTitle: 'Portcastアカウントの作成',
    signUpDescription: '法人用メールアドレスを入力してください。アカウント情報とパスワードがこのメールアドレスに送信されます。',
    registeredSuccessfully: '登録完了',
    signUpMessage: 'アカウントを作成処理中です！24時間以内にご連絡いたします。お待ちいただく間、製品動画をご覧ください。',
  },
  'portLookupPage': {
    code: 'コード',
    country: '国',
    countryCode: '国コード',
    latitude: '緯度',
    longitude: '経度',
    ports: '港',
    search: '港湾名または港湾コードを検索',
  },
  'profilePage': {
    masterOrg: 'マスター組織',
    noOfUsers: 'ユーザー数',
    orgApiKey: '組織のAPIキー',
    orgIdInfo: 'メールアップロードテンプレートに組織IDを記入します。下記よりサンプルテンプレートをダウンロードしてください。',
    organisationApiKey: '組織APIキー',
    organizationId: '組織ID',
    organizationName: '組織名',
    organizations: '組織',
    quotaUtilization: 'クォータ利用状況',
    searchOrganizations: '組織検索',
    title: 'ユーザープロファイル',
    userApiKey: 'ユーザーAPIキー',
    userDetails: 'ユーザー詳細',
    userID: 'ユーザーID',
    viewDetails: '詳細を見る',
    autoArchive: '自動アーカイブ',
    addCustomField: 'カスタムフィールドの追加',
    create: '作成する',
    fieldName: 'フィールド名',
    fieldType: 'フィールドタイプ',
    allowMultipleValues: '複数の値を許可する',
    subFields: 'サブフィールド',
    newCustomField: '新しいカスタムフィールド',
  },
  'singleContainerPage': {
    billOfLadingNo: 'B/L番号',
    copyUrl: 'URLをコピー',
    destination: '目的地',
    detailedEvents: '詳細イベント',
    goBack: '戻る',
    historical: '履歴',
    incidents: 'インシデント',
    lastUpdated: '最終更新日 (UTC)',
    location: '位置',
    mapView: 'マップビュー',
    modeOfTransport: '輸送手段',
    ooops: 'おっと！',
    predicted: '予測',
    predictedActual: '予想*/実際',
    resetZoom: 'ズームをリセット',
    somethingWentWrong: '問題が発生しました',
    source: 'ソース',
    conversations: '会話',
    noMessageFound: 'このコンテナに関する会話は見つかりませんでした',
    messagePlaceholder: 'タグ付けするには@を使用します。メールが送信されます',
    noMessageFoundError: '会話の取得中にエラーが発生しました。もう一度やり直してください。',
    fetchingMessages: '会話を取得中...',
    tryAgainLater: 'しばらくしてからもう一度お試しください。',
  },
  'vesselsLookupPage': {
    breadthExtreme: '全幅',
    callSign: 'コールサイン',
    deadWeight: 'デッドウェイト',
    draught: 'ドラフト',
    engineBuilder: 'エンジンビルダー',
    enginePower: 'エンジン出力',
    engineStroke: 'エンジンストローク',
    grossTonnage: '総トン数',
    search: '船舶を検索',
    speed: '速度',
    type: 'タイプ',
    vessels: '船舶',
  },
  'navBar': {
    'control_tower_dashboard': 'コントロールタワー',
    'co2_report_dashboard': 'CO2レポート',
    'port_congestion': '港の混雑状況',
    'historicalTransit': 'トランジットタイム',
    'dashboard': 'コントロールダッシュボード',
    'exception_dashboard': '例外',
    'historical_transit_time': '過去のトランジットタイム',
    'accuracy_dashboard': '正確性',
    'latency_accuracy_dashboard': 'カバレッジとレイテンシー',
    'vessel-tracking': '船舶追跡',
    'report-dashboard': 'レポートビルダー',
    'report-builder': 'レポートビルダー',
    'login': 'ログイン',
    'profile': 'プロフィールページ: {initials}',
    'container-tracking': 'コンテナ追跡',
    'container-tracking-id': '{containerId} - コンテナ単体追跡',
    'container-tracking-id-email': '{containerId} - コンテナ単体追跡',
    'container-tracking-master-report': 'マスターレポート',
    'container-tracking-api': 'APIレスポンス',
    'container-tracking-cached': 'キャッシュ済みブックマーク',
    'container-tracking-typesense': '検索インデックスオブジェクト（Typesense）',
    'upload-logs-container': 'コンテナログ',
    'upload-logs-vessel': '船舶ログ',
    'sailing-schedule': '航海スケジュール',
    'metadata_ports': '港湾メタデータ',
    'metadata_carriers': 'キャリアのメタデータ',
    'metadata_vessels': '船舶メタデータ',
  },
  'onBoarding': {
    title: 'はじめに',
  },
  'Arrived at POD': 'POD到着',
  'Arrived at T/S': 'T/S到着',
  'Departed from POL': 'POLを出港',
  'Departed from T/S': 'T/Sを出港',
  'Discharged at POD': 'PODで荷降ろし済み',
  'Discharged at T/S': 'T/Sで荷降ろし済み',
  'Empty Return': '空返送',
  'Gate In Full': 'フルコンテナでゲートイン',
  'Gate Out Empty': '空コンテナでゲートアウト',
  'Gate Out Full': 'フルコンテナでゲートアウト',
  'Loaded at POL': 'POLで荷積み済み',
  'Loaded at T/S': '中継地(T/S)で荷積み済み',
  'v2': {
    sideNav: {
      addContainers: 'コンテナを追加',
      addContainer: 'コンテナを追加',
      addContainersSubtitle: '残り{count}クレジット',
      creditMinimumWarningModalText: 'クレジットの上限が近づいています。増額をご希望の場合は、弊社までお気軽にご連絡ください。',
      creditZeroErrorModalText: 'クレジット上限を超えました。これ以上コンテナをアップロードできません。増枠をご希望の場合は、弊社までお気軽にご連絡ください。',
      dashboard: '概要',
      tracking: '追跡',
      vesselTracking: '船舶追跡',
      containerTracking: 'コンテナ追跡',
      reports: 'レポート',
      portcast_indices: 'Portcast指標',
      portCongestion: '港湾混雑状況',
      developerTools: '開発者ツール',
      containerLogs: 'コンテナログ',
      vesselLogs: '船舶ログ',
      carrierLookup: 'キャリア検索',
      portLookup: '港湾検索',
      vesselLookup: '船舶検索',
      helpCenter: 'サポート',
      knowledgeBase: 'ナレッジベース',
      reportAnIssue: '問題を報告',
      changeLog: '変更ログ',
      apiDocs: '変更ログ',
      profile: 'マイプロフィール',
      logout: 'ログアウト',
    },
    singleContainerPage: {
      plannedArrival: '当初のスケジュール',
      originalETA: '当初のスケジュール',
      latestETA: '最新スケジュール',
      predictedETA: '最新予測',
      downloadReport: 'レポートをダウンロード',
      download: 'ダウンロード',
      shareURL: 'URLを共有',
      share: '共有',
      shareURLToolTip: 'URLをコピーするにはここをクリック',
      shareURLCopyMessage: 'URLがクリップボードにコピーされました',
      seeRelatedShipments: '関連する貨物を表示',
      seeAllShipmentsOnVessel: '本船のすべての貨物を表示',
      noRelatedShipments: 'このB/L番号の他のコンテナは見つかりませんでした',
      viewContainerDetails: '詳細イベントを表示',
      blNo: 'B/L番号',
      newUI: '新UI',
      oldUI: '旧UI',
      tabs: {
        detailedEvents: {
          title: '詳細イベント',
        },
        productDetails: {
          title: '商品詳細',
        },
        terminalDetails: {
          title: 'ターミナル詳細',
        },
        conversations: {
          title: '会話',
        },
      },
      arrivalAtPOD: 'POD到着',
      carrierArrival: 'キャリア到着',
      blNumber: 'B/L番号',
      carrierLatestVessel: 'キャリア＆最新船舶',
    },
    noLoginBanner: {
      title: 'Portcastに参加して',
      point1: '全キャリアのコンテナ追跡データを一括で',
      point2: 'マニュアルベースで追跡する手間を省けます',
      point3: 'メールやスマホで自動アップデート',
      requestAccess: 'アクセスを要求',
    },
    bulkUpload: {
      containerBulkUpload: '方法1: コンテナを一括で追加',
      containerManuallyUpload: '方法2: コンテナを手動で追加',
      bulkUploadText: 'ここにファイルをドロップするか、クリックしてファイルを追加',
      uploadSupportFile: '.CSV、.XLS、.XlSXに対応しています',
      downloadSampleUploadFile: 'サンプルアップロードファイルをダウンロード',
      addManuallyContainers: '手動でコンテナを追加するにはここをクリック',
      addingManuallyTakeMoreTime: '手動でのコンテナ追加には、通常より時間がかかります',
      mapColumns: 'アップロードしたファイルの列を以下のフィールドにマッピングします',
      addContainersManually: '手動でコンテナを追加する',
    },
  },
  'welcomeDialog': {
    title: 'ようこそ ！',
    description: 'コンテナの追跡を開始するには、まずコンテナを追加します。アップロードを開始するには、「コンテナを追加」ボタンをクリックしてください。',
    processingTitle: 'アップロードを処理中...',
    processingDescription: '処理に数分かかる場合があります。コンテナのアップロード完了時にお知らせいたします。',
  },
  'co2EmissionLockDialog': {
    title: 'CO2e排出量がロックされています',
    description: 'CO2排出量は現在ロックされています。排出量データのロックを解除するには、弊社までお気軽にご連絡ください。',
  },
  'predictionsLockDialog': {
    title: '予測機能がロックされています',
    description: 'コンテナ視認性の予測を解除しますか？予測はより正確で、毎日更新されます。予測のロックを解除するには、弊社までお気軽にご連絡ください。',
  },
}
