export const zh_CN = {
  'carrierLookupPage': {
    carriers: '承运人',
    fullName: '全名',
    search: '搜索承运人或 SCAC',
    synonyms: '同义词',
  },
  'common': {
    showJson: '显示 Json',
    carrierTime: '运营商时间',
    dashboard: '仪表板',
    requestReceived: '已收到请求',
    viewTransitTimeDashboard: '查看历史运输时间',
    requestReceivedMessage: '感谢您与我们联系。我们的团队将在接下来的 48 小时内与您联系。',
    noThanks: '不，谢谢',
    contactUs: '联系我们',
    contactUsToUnlock: '联系我们解锁',
    dataUnavailable: '数据不可用',
    co2Emission: '二氧化碳排放量',
    emission: '排放',
    locked: '锁定',
    predictionLocked: '预测锁定',
    preferences: '优先',
    enableNewUI: '启用新用户界面',
    iAccept: '我接受',
    termsOfUse: '使用条款',
    openInNewWindow: '在新窗口中打开',
    unavailable: '不可用',
    availableSoon: '即将上市',
    send: '发送',
    portCongestion: '港口拥堵',
    developerTooling: '开发者工具',
    activeOrg: ' 现行的组织',
    actual_arrival: '实际到货',
    added_on: '添加在',
    anchorageStay: '安克雷奇住宿',
    apiDocumentation: 'API 文档',
    archive: '档案',
    archived: '存档',
    arrival_status: '到达状态',
    arrive_at_pod: '抵达 POD',
    arrive_at_pot: '到达 POT',
    arrived: '到达的',
    arriving: '到达',
    ts_waiting_time_in_days: 'TS等待时间（天）',
    is_transhipment: '到达',
    bls: 'bls',
    booking_no_: '预订编号',
    byCarrier: '由承运人',
    cancel: '取消',
    carriers: '承运人',
    confirm: '确认',
    confirmMessage: '您确定要继续吗？',
    consignee: '收货人',
    container: '容器',
    containerNotMappedWithBL: '容器未映射到 BL',
    containerNotTrackedByCarrier: '承运人未跟踪集装箱',
    containers: '容器',
    contents: '内容',
    copied: '已复制',
    copy: '复制',
    count: '数数',
    days: '天',
    delay_in_days: '延迟天数',
    delete: '删除',
    depart_at_pol: '在 POL 出发',
    depart_at_pot: '在 POT 出发',
    direct: '直接的',
    discharge: '释放',
    discharged: '已卸货',
    done: '完毕',
    download: '下载',
    downloadAll: '全部下载',
    early: '早期的',
    earlyJourney: '早期旅程',
    edit: '编辑',
    email: '电子邮件',
    emptyReturn: '空返回',
    error: '错误',
    errors: '错误',
    events: '事件',
    exceptions: '控制仪表板',
    from: '从',
    gateOut: '门出',
    gatein_at_pol: '进入 POL',
    gateout_at_pod: '在 POD 出闸',
    gateout_at_pol: '在 POL 出闸',
    importer: '进口商',
    inThis: '在这',
    incidentList: {
      anchorageStay: '安克雷奇住宿',
      nonOptimalRoute: '非最优路线',
      outdatedSchedule: '过时的时间表',
      portStay: '港口住宿',
    },
    helpCenter: '帮助中心',
    incidents: '事件',
    init: '在里面',
    internal_ref_no_: '内部参考编号',
    late: '晚的',
    load_at_pol: '负载在 POL',
    load_at_pot: '在 POT 加载',
    loading: '正在加载...',
    logout: '登出',
    logs: '日志',
    lookup: '抬头',
    new: '新的',
    noDataFromCarrier: '没有来自承运人的数据',
    nonOptimalRoute: '非最优路线',
    of: '的',
    okay: '好的',
    onTime: '准时',
    others: '其他',
    or: '或者',
    organization: '组织',
    outdatedSchedule: '过时的时间表',
    p_o_no_: '邮政信箱编号',
    password: '密码',
    pod: '出发港口',
    pol: '登陆港口',
    pol_waiting_time_in_days: 'POL 等待时间（天）',
    portNotInSchedule: '港口不在计划中',
    portStay: '港口住宿',
    ports: '港口',
    predicted_arrival: '预计到达',
    proceed: '继续',
    refreshDataConfirmMessage: '确定要刷新 {total} 个容器数据吗？',
    reindexDataConfirmMessage: '您确定要重新索引数据吗？',
    releaseNotes: '版本注释',
    return_at_pod: '在 POD 返回',
    save: '保存',
    searchOrgs: '搜索组织',
    selected: '已选择',
    showAll: '显示所有',
    status: '状态',
    statusList: {
      'Arrived': '到达的',
      'NO_DATA_RECEIVED_FROM_CARRIER': '运营商没有数据',
      'BL_NOT_FOUND': '未找到 BL',
      'BULK_INIT': '准备预测',
      'CONTAINER_IDLE_AFTER_GATEOUT': '关门后集装箱闲置',
      'CONTAINER_NOT_FOUND': '找不到容器',
      'CONTAINER_NOT_MAPPED_WITH_BL': '容器未映射到 BL',
      'CONTAINER_RETURNED_TO_CARRIER': '集装箱退回承运人',
      'INIT': '准备预测',
      'NO_DATA_FROM_PROVIDER': '没有来自承运人的数据',
      'On Time': '准时',
      'Preparing Predictions': '准备预测',
      'SUCCESS': '成功',
      'SUCCESS_EARLY_JOURNEY': '早期旅程',
      'EARLY_JOURNEY': '早期旅程',
      'TARGET_PORT_NOT_IN_ROTATION': '港口不在计划中',
      'earlyByXDays': '提前 {days} 天到达',
      'lateByXDays': '迟到 {days} 天',
      'delayByDays': '延迟 {days} 天',
    },
    status_code: '状态码',
    stop: '停止',
    stops: '停止',
    supplier: '供应商',
    switchActiveOrg: '切换现行的组织',
    switchTab: '现在请切换到另一个标签',
    t_m_s_ref_no_: 'TMS 参考编号',
    t_s: 'T/S',
    thereAreNo: '没有',
    unique: '独特的',
    unknownError: '未知错误',
    unload_at_pod: '在 POD 卸载',
    unload_at_pot: '在 POT 卸货',
    updatedSuccessfully: '更新成功',
    updated_on: '更新时间',
    uploadLogs: '上传日志',
    vessel: '船舶',
    vesselTracking: '船舶追踪',
    vessels: '船舶',
    viewProfile: '查看资料',
    makeAsDefault: '设为默认',
    containerNumber: '货柜号',
    billNumber: '提单号',
    organisation: '组织',
    addedUpdated: '添加和更新',
    exceptionsIncidents: '例外与事件',
    poNumber: '订单号',
    tmsRefNumber: 'TMS 参考编号',
    content: '内容',
    tableView: '表视图',
    calenderView: '日历视图',
    mapView: '地图视图',
    customLate: '自定义延迟',
    viewName: '查看名称',
    viewDescription: '查看说明',
    optional: '选修的',
    setAsDefaultView: ' 设置为默认视图。',
    portOfLoading: '装货港',
    transhipment: '转运',
    transhipments: '转运',
    portOfDeparture: '卸货港',
    search: '搜索...',
    routeChange: '路线变更',
    anchorageDelay: '锚地延迟',
    portSequenceChange: '端口顺序改变',
    carrierConsideringDelay: '承运人考虑延误',
    weatherAdversity: '天气逆境',
    delayAtUpcomingPort: '即将到来的港口延误',
    carrierDataMissing: '运营商数据丢失',
    invalidInput: '输入无效',
    remove: '消除',
    filters: '过滤器',
    loadingPleaseWait: '加载请稍候',
    original: '原来的',
    portcast: '端口广播',
    clearAll: '全部清除',
    active: '积极的',
    bookmarked: '已收藏',
    bookmark: '书签',
    filterAllMessage: 'All：所有容器，包括 Active、Archived 和 With Error。',
    filterActiveMessage: '活动：主动跟踪的容器。此列表不包括有错误或已存档的容器。',
    filterNewMessage: '新：最近 24 小时内上传的容器。',
    filterLateMessage: '迟到：所有迟到的活动容器。',
    filterArrivedMessage: '已到达：以下容器已到达 POD。',
    filterBookmarkedMessage: '已添加书签：以下容器已被该组织中的一位用户添加为书签。',
    selectAllContainers: '选择所有 {count} 个容器',
    scheduleAlertMessage: '您要为这些容器安排警报吗？接收有关发送到您的电子邮件或手机号码的关键容器里程碑的通知',
    scheduleAlerts: '安排提醒',
    dontSchedule: '不安排',
    bookmarkContainerMessage: '您要为这些容器添加书签吗？它将帮助您确定重要容器的优先级。',
    bookmarkContainers: '书签容器',
    dontBookmark: '不要收藏',
    downloadContainerMessage: '您要下载容器吗？',
    downloadVesselMessage: '您想下载船只吗？',
    download300ContainerMessage: '您要下载选定的容器吗？选择超过 300 个容器可能需要更长的时间来处理。',
    download300VesselMessage: '您想下载选定的船只吗？选择超过 300 艘船舶可能需要更长的时间来处理。',
    dontDownload: '取消',
    shareViaEmail: '通过电子邮件分享',
    downloadNow: '现在下载',
    archiveContainerMessage: '您要归档容器吗？归档将停止对选定容器的跟踪。',
    archiveContainer: '存档容器',
    dontArchive: '不存档',
    deleteContainerMessage: '删除容器是永久性的。它将从您的列表中删除。',
    deleteVesselsMessage: '删除船只是永久性的。它将从您的列表中删除。',
    deleteContainer: '删除容器',
    deleteVessel: '删除船只',
    dontDelete: '不要删除',
    eta: '预计到达时间',
    carrierVessel: '承运人/船舶',
    action: '行动',
    searchByContainer: '按容器、提单或采购订单编号搜索',
    searchByPodPolTs: '按 POL、POD 或 T/S 搜索',
    searchByVesselCarrier: '按船舶或承运人搜索',
    searchByCustomer: '按客户搜索',
    searchByVessel: '按船号或提单号搜索',
    applyGroupBy: '申请分组依据',
    applySort: '应用排序',
    showContainers: '显示容器',
    switchOrganisation: '切换组织',
    showCountContainers: '显示 {count} 个容器',
    showCountVessels: '显示 {count} 艘船只',
    applyFilters: '应用过滤器',
    saveView: '保存视图',
    savedViews: '保存的视图',
    selectView: '选择视图',
    addNew: '添新',
    chooseView: '选择视图',
    applyView: '应用视图',
    asDefault: '默认',
    set: '放',
    viewNameError: '视图名称已存在',
    lateByDays: '迟到 {start} 至 {end} 天',
    lateByDaysNumber: '迟到 {count}+ 天',
    latestEvents: '最新活动',
    masterOrg: '主组织',
    emptyGateOutAtPOL: '空门输出（在 POL）',
    gateInFullAtPOL: '全门（在 POL）',
    loadedAtPOL: '已加载（在 POL）',
    departedFromPOL: '出发（来自 POL）',
    arrivalAtPOD: '到达（POD）',
    dischargedAtPOD: '出院（在 POD）',
    gateOutFullAtPOD: 'Gate Out Full（在 POD）',
    emptyReturnAtPOD: '清空返回（在 POD）',
    lateStatus: '迟到状态',
    created: '已创建',
    updated: '更新',
    aisSatellite: 'AIS 卫星数据',
    containerNotFound: '未找到容器。<br>尝试删除一些过滤器。',
    vesselsNotFound: '未找到船只。<br>尝试删除一些过滤器。',
    comingSoon: '即将推出',
    pleaseTryAgain: '请稍后再检查并报告进一步调查',
    port: '港口',
    country: '国家',
    pol_country: '波尔国家',
    pod_country: '豆荚国',
    clickToCopy: '点击复制',
    clickToCopyContainer: '点击复制集装箱号',
    generatingPrediction: '生成预测',
    latest: '最新的',
    dontHaveAnAccount: '还没有帐户？',
    alreadyHaveAnAccount: '已经有帐户？',
    corporateEmailInvalidError: '请使用企业邮箱进行注册。',
    noOrgDialogTitle: '联系管理部门',
    noOrgDialogDescription: '您没有分配组织，请联系管理人员。',
    thankYou: '谢谢',
    needsAttention: '需要注意',
    onTheWay: '在途中',
    targetPort: '目标端口',
    targetPortEta: '目标港口埃塔',
    PredictedActual: '预测 * / 实际',
    scheduledArrival: '如期到来',
    errorCode: '错误代码',
    flaggedOn: '标记为开启',
    showVessels: '显示船只',
    selectAllVessels: '选择所有 {count} 艘船只',
    restart: '重新开始',
    reIndexContainers: '重新索引容器',
    reIndexMessage: '您确定要重新索引容器数据吗？',
    refresh: '刷新',
    refreshVessels: '刷新船只',
    refreshVesselsMessage: '您确定要刷新 {count} 艘船舶数据吗？',
    downloadSuccess: '容器下载成功。',
    somethingWentWrong: '出了些问题。请稍后再试。',
    uploadingLimitExceed: '您的上传配额已超出',
    containerUploadedSuccessfully: '容器上传成功',
    reportType: '报告类型',
    timeRange: '时间范围',
    selectFields: '选择字段',
    departingContainers: '出发集装箱',
    departedContainers: '离开的集装箱',
    arrivingContainers: '抵达集装箱',
    arrivedContainers: '集装箱抵达',
  },
  'containerPage': {
    actual: '实际的',
    actualArrivalAscending: '实际到达 - 升序',
    actualArrivalDescending: '实际到达 - 降序',
    addContainer: '添加容器',
    addContainers: '添加容器',
    addNewContainers: '添加新容器',
    addMoreContainers: '添加更多容器',
    addedOn: '添加在',
    addedOnMasterOrg: '组织/添加在',
    addedOnSortByItems: {
      created_asc: '已创建 - 升序',
      created_desc: '已创建 - 降序',
    },
    allTab: '全部',
    archiveContainers: '存档容器',
    archivedTab: '存档',
    arrival: '到达',
    arrivalSortByItems: {
      pod_ais_actual_arrival_asc: '实际到达 - 升序',
      pod_ais_actual_arrival_desc: '实际到达 - 降序',
      pod_portcast_predicted_arrival_asc: '预计到达 - 升序',
      pod_portcast_predicted_arrival_desc: '预计到达 - 降序',
    },
    arrivalStatus: '到达状态',
    arrivedAtPOD: '到达 POD',
    arrivedAtTS: '到达 T/S',
    arrivedTab: '到达的',
    arrivingTab: '到达',
    billOfLading: '提货单',
    bookingInfo: '预订信息。',
    bookingInformation: '预订信息',
    bookingNo: '预订号',
    bookingNumber: '订单编号',
    browseFiles: '浏览文件',
    bulkUpload: '批量上传',
    carrier: '载体',
    carrierNo: '承运人编号',
    carrierSCAC: '承运人/SCAC',
    carrierSCACVessels: '承运人 SCAC / 船舶',
    clear: '清除',
    clearAppliedFilters: '清除应用筛选器',
    consignee: '收货人',
    containerArchiveSuccessful: '成功归档 {count} 个容器！！',
    containerEvent: '容器事件',
    containerNo: '集装箱号',
    containerNoBl: '货柜号/提单',
    containerNotMappedWithBL: '容器未映射到 BL',
    containerNotTrackedByCarrier: '承运人未跟踪集装箱',
    containerUploadErrorDuplicate: '重复上传尝试',
    containerUploadErrorInvalidContainer: '无效的容器编号',
    containerUploadErrorInvalidCarrier: '运营商编号无效',
    containerUploadErrorQuotaExceeded: '超出配额',
    containerUploadErrorMissingCarrier: '缺少承运',
    containerUploadErrorMissingContainer: '缺少容器编号',
    contentBrand: '内容/品牌',
    contents: '内容',
    contractualOriginalETA: '合同/原始预计到达时间',
    contractualOriginalETD: '合同/原始 ETD',
    customer: '顾客',
    deletedAllInvalidText: '您已删除所有无效容器',
    departedFromPOL: '离开POL',
    departedFromTS: '从 T/S 出发',
    departure: '离开',
    destination: '目的地',
    discharge: '卸货',
    dischargedAtPOD: '在 POD 已卸货',
    dischargedAtTS: '在 T/S 处卸货',
    disclaimer: '基于最初由承运人安排的日期',
    downloadAll: '下载所有容器',
    downloadContainers: '下载容器',
    downloadSample: '下载示例 CSV',
    downloadSelected: '下载选定的容器',
    dropFilesHere: '将文件放在这里',
    earlyJourney: '早期旅程',
    earlyTab: '早期的',
    emptyReturn: '空返回',
    enterData: '在此处输入您的数据',
    errorCodeFlaggedByTab: '错误代码/标记者',
    exportErrorCSV: '将错误导出为 CSV',
    fileSupport: '我们支持 .CSV、.XLS 和 .XlSX',
    filterBookingNo: '按预订号码筛选',
    filterByBLs: '按 BL 筛选',
    filterByCarriers: '按承运人筛选',
    filterByContainerNos: '按容器编号筛选',
    filterByPODPorts: '按 POD 港口筛选',
    filterByPOLPorts: '按 POL 港口筛选',
    filterByTSPorts: '按 TS 港口筛选',
    filterByVessels: '按船舶筛选',
    filterConsignee: '按收货人筛选',
    filterContents: '按内容筛选',
    filterImporter: '按进口商筛选',
    filterInternalRef: '按内部参考编号筛选',
    filterPoNo: '按邮政编码筛选',
    filterSupplier: '按供应商筛选',
    filterTmsRef: '按 T.M.S 参考编号筛选',
    finalVesselName: '最终船舶名称',
    firstVesselName: '第一船舶名称',
    flaggedOnTab: '标记为',
    gateInFull: '满门',
    gateOutEmpty: '门出空',
    gateOutFull: '门输出满',
    generateNewPredictions: {
      disabledTitle: '为到达的容器禁用生成预测',
      subTitle: '我们将为您提供集装箱的新 ETA',
      successMessage: '产生新的预测。请在 1-2 分钟后回来查看',
      title: '生成新的预测',
    },
    groupBy: '通过...分组',
    groupingByUnique: '按唯一身份分组',
    importer: '进口商',
    initiallyScheduled: '最初计划',
    plannedArrival: '最初预定',
    planned: '最初的',
    internalRef: '内部参考',
    internalRefNumber: '内部参考编号',
    invalidContainerNo: '无效的货柜编号',
    journey: '旅行',
    lateTab: '晚的',
    latestEvent: '最新事件',
    latestPredicted: '最新（预测）',
    latestScheduled: '最新预定',
    loadedAtPOL: '装在 POL',
    loadedAtTS: '在 T/S 加载',
    loading: '正在加载',
    mapColumns: '地图列',
    mapColumnsHeader: '所选行是否包含列标题？',
    mapColumnsTip: '从文件中选择对应的列',
    miscellaneousInformation: '杂项信息',
    newTab: '新的',
    nextStepsInstruction: '下一步将允许您将电子表格列与正确的数据点相匹配。',
    noDataFromCarrier: '没有来自承运的数据',
    noInvalidContainers: '没有无效的容器。',
    noSailingInfo: '没有航行信息',
    outboundHaulage: '出境运输',
    outboundHaulageID: '出境运输 ID',
    packageQuantity: '包装数量',
    poNo: '邮政编码',
    poNumber: '订单编号',
    pod: '出发港口',
    pol: '登陆港口',
    portNotinSchedule: '港口不在计划中',
    predicted: '预料到的',
    predictedActualArrival: '预计/实际到货',
    predictedArrivalAscending: '预计到达 - 上升',
    predictedArrivalDescending: '预计到达 - 下降',
    preparingPredictions: '准备预测',
    productDescription: '产品描述',
    productDetails: '产品详情',
    productInfo: '产品信息。',
    productInformation: '产品信息',
    redFlagComments: '输入评论，或任何您想添加的内容',
    redFlagContainer: '红旗集装箱',
    redFlaggedSuccessfulMessage: '已成功标记 {count} 个容器。您还可以在红色标记选项卡下找到它们',
    redFlaggedTab: '红色标记',
    refreshOrgData: '刷新数据',
    reindexData: '重新索引数据',
    reviewContainers: '审查容器',
    sailingDates: '航行日期',
    sailingInfo: '航行信息',
    sailingInfoNotAvailable: '由于缺少 POL 或 POD 信息，航行信息不可用。',
    saleInfo: '销售信息',
    salesOrder: '销售订单',
    scheduled: '预定的',
    searchContainers: '搜索容器',
    selectAnotherRow: '选择另一行',
    selectReasonForRedFlag: '选择举报原因',
    showEtaHistory: '显示 ETA 历史',
    sortBy: '排序方式',
    sortByAddedOnDate: '排序方式添加',
    sortByArrivalDate: '按到达日期排序',
    source: '资源',
    sourceFile: '源文件',
    status: '状态',
    statusIncidents: '状态/事件',
    subtextAddContainers: '您可以在此处从源文件中复制和粘贴多行。',
    success: '成功',
    supplier: '供应商',
    supplierID: '供应商编号',
    title: '集装箱追踪',
    tmsRef: 'T.M.S 参考',
    tmsReferenceNumber: 'TMS 参考编号',
    ts: 'TS',
    unflagContainers: '取消标记容器',
    updatedOn: '更新时间',
    uploadAddContainers: '上传/添加容器',
    uploadCompleted: '上传完成',
    uploadFailed: '上传失败',
    uploadFailedMessage: '上传容器失败。',
    uploadMessage1: '您已上传',
    uploadMessage2: '容器成功',
    vessel: '船舶',
    vesselNotConfirmed: '船舶未确认',
    vesselNotConfirmedTooltip: '承运人尚未确认此航段的船名。 48 小时后再检查',
    vesselVoyage: '船名航次',
    viewRecentUploadLogs: '查看最近上传的容器日志',
    search: '搜索',
    origin: '起源',
    selectPort: '选择端口',
    selectCountry: '选择国家',
    addingContainerInProgress: '正在添加容器...',
    AddCountContainers: '添加 {count} 个容器',
    new: '新的',
    createReport: '创建报告',
  },
  'containerUploadLogsPage': {
    all: '全部',
    billOfLadingNo: '提单编号',
    carrierScac: '承运人 SCAC',
    containerNo: '集装箱编号。',
    duplicates: '重复',
    error: '错误',
    invalidContainers: '无效的容器',
    invalidPayload: '无效负载',
    invalidScac: '无效的 SCAC',
    noError: '没有错误',
    outOfQuota: '超出配额',
    recentUploads: '最近上传',
    search: '搜索提货单、集装箱号、承运人或错误',
    title: '容器上传日志',
    uploadedDatetime: '上传日期时间',
    validationStatus: '验证状态',
  },
  'dashboardPage': {
    containersAtPOD: 'POD 的容器',
    containersAtPODToolTip: 'POD 的容器里程碑。使用此小部件来识别清除特定里程碑的容器',
    containersAtTS: 'POT 的停留时间',
    containersAtTSToolTip: '活动容器在 POT 上花费的时间。根据 POT 的加载和卸载事件之间的差异计算。请注意 10 天以上存储桶中的容器，因为它们有被翻车的风险',
    delay10OrMoreDays: '10 天以上',
    delay1To4Days: '等待时间 1-4 天',
    delay5To9Days: '5-9 天',
    delayedContainers: '延迟容器',
    delayedContainersToolTip: '提前、准时或迟到的集装箱配送',
    departureDelayAtPOL: '在 POL 的停留时间',
    departureDelayAtPOLToolTip: '活动容器在 POL 上花费的时间。根据 POL 上的加载事件和完全事件中的门之间的差异计算。请注意 10 天以上存储桶中的容器，因为它们有面临 D&D 费用的风险',
    incidentToolTip: '发生事故的集装箱分布',
    late1To2Days: '迟到：1-2 天',
    late3To5Days: '迟到：3-5 天',
    late5OrMoreDays: '迟到：5 天以上',
    problematicContainers: '有问题的容器',
    problematicContainersToolTip: '有以下问题的容器分布。承运人未跟踪集装箱，承运人无数据，集装箱未与提单映射，早期旅程，港口未按期',
    title: '控制仪表板',
    topTradeLanesUsed: '使用最多的贸易航线',
    topTradeLanesUsedToolTip: '来自唯一港口对的容器计数',
    totalBLs: '总提单',
    totalBLsToolTip: 'Portcast 目前正在跟踪的唯一 BL',
    totalContainers: '容器总数',
    totalContainersToolTip: 'Portcast 当前正在跟踪的唯一容器',
    totalContainersToolTipItalics: '延迟（在海上）+ 到达（在 POD）+ 有问题',
    totalVesselVoyages: '船舶总数/航次',
    totalVesselVoyagesToolTip: 'Portcast 目前正在跟踪的独特船舶/航次',
    waiting10OrMoreDays: '10 天以上',
    waiting5To10Days: '5-10 天',
    waitingTime1To3Days: '等待时间 1-3 天',
    overview: '概述',
    exceptions: '例外',
    analytics: '分析',
    viewAll: '查看全部',
    breakdownLateContainers: '后期集装箱分解',
    totalTracked: '总跟踪',
    active: '现行的',
    activeTooltip: 'Portcast 当前正在跟踪的容器子集',
    withError: '有错误',
    withErrorTooltip: '由于错误而缺少跟踪数据的容器子集',
    inactive: '非活动/存档',
    inactiveTooltip: '不再被跟踪的容器子集。以下包括已手动或以编程方式存档的容器（即，在捕获最后一个容器事件里程碑后 30 天）。',
    totalActive: '总活跃',
    journeyTBC: '旅程待定',
    journeyTBCTooltip: '这些集装箱要么在旅途中太早，要么没有可用的海洋跟踪数据；或承运人尚未确认旅程的最后航程',
    arrivedAtPOD: '到达 POD',
    arrivedAtPODTooltip: '这些集装箱已成功抵达 POD',
    journeyInProgress: '海洋之旅进行中',
    journeyInProgressTooltip: '这些集装箱目前正在运送到 POD',
    withErrors: '有错误',
    carrierDataMissing: '承运数据丢失',
    carrierDataMissingTooltip: '承运人未返回任何数据。这些货物可能太早或已经过期。下一步：Portcast 将检查新的或更新的数据。请在 24 小时后再次尝试检查。',
    invalidInput: '输入无效',
    invalidInputTooltip: '那些可能是错别字。以下集装箱似乎不属于提供的提单/预订编号。下一步：请仔细检查是否使用了正确的集装箱编号、提单/预订编号。',
    unknownError: '未知错误',
    unknownErrorTooltip: '处理这些容器时发生内部错误。接下来是什么：我们正在调查这些问题。请在 12 小时后再次查看。',
    commonReasonsForDelay: '延误的常见原因',
    portStay: '港口住宿',
    portStayTooltip: '当船舶/集装箱在到达港口之前在港口区域等待了额外的时间时，就会显示此事件',
    anchorageStay: '安克雷奇住宿',
    anchorageStayTooltip: '每当船舶/集装箱在抵达港口之前在锚地区域等待额外时间时，就会显示此事件',
    nonOptimalRoute: '非最优路线',
    nonOptimalRouteTooltip: '当船只不再遵循历史最佳路线时，会显示此事件',
  },
  'infiniteLoading': {
    noMore: '未找到更多数据',
    noResults: '找不到更多记录',
    spinner: '正在加载...',
  },
  'loginPage': {
    confirmNewPassword: '确认新密码',
    confirmNewPasswordPlaceholder: '重新输入新的密码',
    emailInvalidError: '不合规电邮',
    emailPlaceholder: '输入注册邮箱',
    workEmailPlaceholder: '输入您的工作电子邮件',
    workEmailValidError: '请使用您的工作电子邮件地址进行注册。',
    emailRequiredError: '电子邮件是必需的',
    firstName: '名',
    firstNameEnter: '输入名字',
    forgotPassword: '忘记密码',
    lastName: '姓',
    lastNameEnter: '输入姓氏',
    login: '登录',
    newPassword: '新密码',
    newPasswordEnter: '输入新密码',
    passwordDoNotMatch: '密码不匹配',
    passwordInvalidError: '无效的密码',
    passwordPlaceholder: '输入密码',
    passwordRequiredError: '密码是必需的',
    passwordResetSuccess: '密码重置成功！请尝试登录',
    resetPassword: '重设密码',
    sendResetCode: '发送重置代码',
    title: '登录',
    verificationCode: '验证码',
    verificationCodeEnter: '输入验证码',
    verificationCodeRequired: '需要验证码',
    welcome: '欢迎来到',
    signUp: '报名',
    signUpTitle: '创建您的 Portcast 帐户',
    signUpDescription: '输入您的企业电子邮件。您的帐户凭据和密码将发送到此电子邮件。',
    registeredSuccessfully: '注册成功',
    signUpMessage: '帐户创建正在进行中！您将在 24 小时内收到我们的消息。同时，请观看我们的产品视频，了解我们提供的产品。',
  },
  'portLookupPage': {
    code: '代码',
    country: '国家',
    countryCode: '国家代码',
    latitude: '纬度',
    longitude: '经度',
    ports: '港口',
    search: '搜索港口名称或港口代码',
  },
  'profilePage': {
    masterOrg: '主组织',
    noOfUsers: '用户数',
    orgApiKey: '组织 API 密钥',
    orgIdInfo: ' 在电子邮件上传模板中包含组织 ID。下载下面的示例模板。',
    organisationApiKey: '组织 API 密钥',
    organizationId: '组织 ID',
    organizationName: '机构名称',
    organizations: '组织',
    quotaUtilization: '配额利用',
    searchOrganizations: '搜索组织',
    title: '用户资料',
    userApiKey: '用户 API 密钥',
    userDetails: '用户详情',
    userID: '用户身份',
    viewDetails: '查看详情',
    autoArchive: '自动存档',
    addCustomField: '添加自定义字段',
    create: '创造',
    fieldName: '字段名称',
    fieldType: '字段类型',
    allowMultipleValues: '允许多个值',
    subFields: '子字段',
    newCustomField: '新的自定义字段',
  },
  'singleContainerPage': {
    billOfLadingNo: '提单号',
    copyUrl: '复制网址',
    destination: '目的地',
    detailedEvents: '详细事件',
    goBack: '回去',
    historical: '历史的',
    incidents: '事故',
    lastUpdated: '最后更新 (UTC)',
    location: '地点',
    mapView: '地图视图',
    modeOfTransport: '交通方式',
    ooops: '哎呀！',
    predicted: '预料到的',
    predictedActual: '预测 */实际',
    resetZoom: '重置缩放',
    somethingWentWrong: '出问题了',
    source: '资源',
    conversations: '对话',
    noMessageFound: '找不到此容器的对话',
    messagePlaceholder: '在此处添加您的评论...使用@ 标记人员。将向他们发送一封电子邮件',
    noMessageFoundError: '提取对话时出错。请再试一次。',
    fetchingMessages: '正在获取对话...',
    tryAgainLater: '请稍后再试',
  },
  'vesselsLookupPage': {
    breadthExtreme: '广度极限',
    callSign: '呼号',
    deadWeight: '自重',
    draught: '草稿',
    engineBuilder: '引擎制造商',
    enginePower: '发动机功率',
    engineStroke: '发动机冲程',
    grossTonnage: '总吨位',
    search: '搜索船舶',
    speed: '速度',
    type: '类型',
    vessels: '船舶',
  },
  'navBar': {
    'control_tower_dashboard': '控制塔',
    'co2_report_dashboard': '二氧化碳报告',
    'port_congestion': ' 港口拥堵',
    'historicalTransit': '运输时间',
    'dashboard': '控制仪表板',
    'exception_dashboard': '例外情况',
    'historical_transit_time': '历史运输时间',
    'accuracy_dashboard': '准确性',
    'latency_accuracy_dashboard': '覆盖范围和延迟',
    'vessel-tracking': '船只追踪',
    'report-dashboard': '报告生成器',
    'report-builder': '报告生成器',
    'login': '登录',
    'profile': '个人资料页面：{首字母}',
    'container-tracking': '集装箱追踪',
    'container-tracking-id': '{containerId}',
    'container-tracking-id-email': '{containerId} - 单个容器跟踪',
    'container-tracking-master-report': '主报告',
    'container-tracking-api': 'API 响应',
    'container-tracking-cached': '缓存书签',
    'container-tracking-typesense': '搜索索引对象（Typesense）',
    'upload-logs-container': '容器日志',
    'upload-logs-vessel': '船舶日志',
    'sailing-schedule': '航行时间表',
    'metadata_ports': '港口元数据',
    'metadata_carriers': '承运元数据',
    'metadata_vessels': '船舶元数据',
  },
  'onBoarding': {
    title: '入门',
  },
  'Arrived at POD': '到达 POD',
  'Arrived at T/S': '到达 T/S',
  'Departed from POL': '离开POL',
  'Departed from T/S': '从 T/S 出发',
  'Discharged at POD': '在 POD 已卸货',
  'Discharged at T/S': '在 T/S 处卸货',
  'Empty Return': '空返回',
  'Gate In Full': '满门',
  'Gate Out Empty': '门出空',
  'Gate Out Full': '门输出满',
  'Loaded at POL': '装在 POL',
  'Loaded at T/S': '在 T/S 加载',
  'v2': {
    sideNav: {
      addContainers: '添加容器',
      addContainer: '添加容器',
      addContainersSubtitle: '还剩{count}次',
      creditMinimumWarningModalText: '您当前的信用额度即将到达。如需提高额度，请与我们联系。',
      creditZeroErrorModalText: '您当前的信用额度已用尽。您无法再上传更多货柜。如需提高额度，请与我们联系。',
      dashboard: '概述',
      tracking: '追踪',
      vesselTracking: '轨道船',
      containerTracking: '跟踪集装箱',
      reports: '报告',
      portcast_indices: '港播指数',
      portCongestion: '港口拥堵',
      developerTools: '开发工具',
      containerLogs: '容器日志',
      vesselLogs: '船舶日志',
      carrierLookup: '运营商查询',
      portLookup: '端口查询',
      vesselLookup: '船只查询',
      helpCenter: '轨道船',
      knowledgeBase: '知识库',
      reportAnIssue: '报告问题',
      changeLog: '更改日志',
      apiDocs: 'API文档',
      profile: '我的简历',
      logout: '登出',
    },
    singleContainerPage: {
      plannedArrival: '最初预定',
      originalETA: '原始预计到达时间',
      latestETA: '最新预定',
      predictedETA: '预计预计到达时间',
      downloadReport: '下载报告',
      download: '下载',
      shareURL: '分享网址',
      share: '分享',
      shareURLToolTip: '点击此处复制网址',
      shareURLCopyMessage: 'URL 已复制到剪贴板',
      seeRelatedShipments: '查看相关货件',
      seeAllShipmentsOnVessel: '查看所有船上货件',
      noRelatedShipments: '在该提单号下未发现其他集装箱',
      viewContainerDetails: '查看详细事件',
      blNo: '提单号',
      newUI: '新用户界面',
      oldUI: '旧用户界面',
      tabs: {
        detailedEvents: {
          title: '详细事件',
        },
        productDetails: {
          title: '产品详情',
        },
        terminalDetails: {
          title: '航站楼详情',
        },
        conversations: {
          title: '对话',
        },
      },
      arrivalAtPOD: '到达 POD',
      carrierArrival: '承运人到达',
      blNumber: '提单号',
      carrierLatestVessel: '承运人和最新船只',
    },
    noLoginBanner: {
      title: '加入Portcast获得',
      point1: '所有承运人集装箱跟踪数据集中在一个地方',
      point2: '您的团队不再需要手动跟踪',
      point3: '自动更新您的电子邮件和手机',
      requestAccess: '申请进入',
    },
    bulkUpload: {
      containerBulkUpload: '方法一：批量添加容器',
      containerManuallyUpload: '方法二：手动添加容器',
      bulkUploadText: '将您的文件拖放到此处或单击此处添加文件',
      uploadSupportFile: '我们支持 .CSV、.XLS 和 .XlSX',
      downloadSampleUploadFile: '下载示例上传文件',
      addManuallyContainers: '单击此处手动添加容器',
      addingManuallyTakeMoreTime: '手动添加它们通常需要更多时间',
      mapColumns: '将上传文件中的列映射到以下字段',
      addContainersManually: '手动添加容器',
    },
  },
  'welcomeDialog': {
    title: '欢迎上船！！',
    description: '要开始跟踪容器，第一步是添加它们。要开始上传过程，只需单击“添加容器”按钮即可。',
    processingTitle: '正在处理上传...',
    processingDescription: '这可能需要几分钟。容器上传成功后，我们会通知您。',
  },
  'co2EmissionLockDialog': {
    title: 'CO2e 排放锁定',
    description: '您的帐户目前已锁定二氧化碳排放量。请联系我们解锁排放数据。',
  },
  'predictionsLockDialog': {
    title: '预测已锁定',
    description: '您想解锁容器可见性的预测吗？预测更加准确，并且每天都会更新。请联系我们来解锁预测。',
  },
}
