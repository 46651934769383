import { en } from './locales/en.js';
import { es } from './locales/es.js';
import { fr } from './locales/fr.js';
import { ja } from './locales/ja.js';
import { zh_CN } from './locales/zh_CN.js';
import { zh_TW } from './locales/zh_TW.js';

export default defineI18nConfig(() => ({
  strategy: 'no_prefix',
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    es,
    fr,
    ja,
    zh_CN,
    zh_TW,
  },
}));
