export const es = {
  'carrierLookupPage': {
    carriers: 'Navieras',
    fullName: 'Nombre completo',
    search: 'Búsqueda por Naviera o SCAC',
    synonyms: 'Sinónimos',
  },
  'common': {
    showJson: 'Mostrar Json',
    carrierTime: 'Tiempo del transportista',
    dashboard: 'Panel',
    requestReceived: 'Solicitud recibida',
    viewTransitTimeDashboard: 'Ver el tiempo de tránsito histórico',
    requestReceivedMessage: 'Gracias por ponerse en contacto con nosotros. Nuestro equipo se comunicará con usted dentro de las próximas 48 horas.',
    noThanks: 'No, gracias',
    contactUs: 'Contáctenos',
    contactUsToUnlock: 'Contáctenos para desbloquear',
    dataUnavailable: 'Datos no disponibles',
    co2Emission: 'Emisión de CO2e',
    emission: 'Emisión',
    locked: 'bloqueado',
    predictionLocked: 'Predicción bloqueada',
    preferences: 'preferencias',
    enableNewUI: 'Habilitar nueva interfaz de usuario',
    iAccept: 'acepto el',
    termsOfUse: 'Condiciones de uso',
    openInNewWindow: 'Se abre en ventana nueva',
    unavailable: 'Indisponible',
    availableSoon: 'Estará disponible pronto',
    send: 'Enviar',
    portCongestion: 'Congestion de los puertos',
    developerTooling: 'Herramientas para desarrolladores',
    activeOrg: 'Organización activa',
    actual_arrival: 'Arribo real',
    added_on: 'Añadido',
    anchorageStay: 'Permanencia en fondeo',
    apiDocumentation: 'Documentación API',
    archive: 'Archivo',
    archived: 'Archivado',
    arrival_status: 'Estado de arribo',
    arrive_at_pod: 'Arribo a POD',
    arrive_at_pot: 'Arribo a POT',
    arrived: 'Arribó',
    arriving: 'llegando',
    ts_waiting_time_in_days: 'Tiempo de espera de TS (días)',
    is_transhipment: 'llegando',
    bls: 'BLS',
    booking_no_: 'Nº de reserva',
    byCarrier: 'Por Naviera',
    cancel: 'Cancelar',
    carriers: 'Navieras',
    confirm: 'Confirmar',
    confirmMessage: '¿Estas seguro que deseas continuar?',
    consignee: 'Consignatario',
    container: 'Contenedor',
    containerNotMappedWithBL: 'Contenedor no asignado con BL',
    containerNotTrackedByCarrier: 'Contenedor no rastreado por la naviera',
    containers: 'Contenedores',
    contents: 'Contenido',
    copied: 'copiado',
    copy: 'Copiar',
    count: 'contar',
    days: 'Días',
    delay_in_days: 'Retraso en Días',
    delete: 'Borrar',
    depart_at_pol: 'Zarpe del POL',
    depart_at_pot: 'Zarpe del POT',
    direct: 'directo',
    discharge: 'Descargue',
    discharged: 'Descargado',
    done: 'Hecho',
    download: 'Descargar',
    downloadAll: 'Descargar todo',
    early: 'Temprano',
    earlyJourney: 'viaje temprano',
    edit: 'editar',
    email: 'Correo electrónico',
    emptyReturn: 'Retorno vacío',
    error: 'Error',
    errors: 'errores',
    events: 'Eventos',
    exceptions: 'Excepciones',
    from: 'de',
    gateOut: 'Retiro de puerto',
    gatein_at_pol: 'Entrada en POL',
    gateout_at_pod: 'Retiro de POD',
    gateout_at_pol: 'Reitro de POL',
    importer: 'Importador',
    inThis: 'en esto',
    incidentList: {
      anchorageStay: 'Permanencia en fondeo',
      nonOptimalRoute: 'Ruta no óptima',
      outdatedSchedule: 'Itinerario desactualizado',
      portStay: 'Estancia en puerto',
    },
    helpCenter: 'Centro de ayuda',
    incidents: 'incidentes',
    init: 'Inicio',
    internal_ref_no_: 'Referencia interna No.',
    late: 'Retrasado',
    load_at_pol: 'Cargue en POL',
    load_at_pot: 'Cargue en POT',
    loading: 'Cargando ...',
    logout: 'Cerrar sesión',
    logs: 'Registros',
    lookup: 'Buscar',
    new: 'nuevo',
    noDataFromCarrier: 'Sin información de la naviera',
    nonOptimalRoute: 'Ruta no óptima',
    of: 'de',
    okay: 'De acuerdo',
    onTime: 'A tiempo',
    others: 'Otros',
    or: 'O',
    organization: 'Organización',
    outdatedSchedule: 'Itinerario desactualizado',
    p_o_no_: 'Número de orden de compra ',
    password: 'Clave',
    pod: 'POD',
    pol: 'POL',
    pol_waiting_time_in_days: 'POL Tiempo de espera en días',
    portNotInSchedule: 'Puerto no está en itinerario',
    portStay: 'Estancia en puerto',
    ports: 'Puertos',
    predicted_arrival: 'Llegada estimada',
    proceed: 'Proceder',
    refreshDataConfirmMessage: '¿Está seguro de que desea actualizar los datos de {total} contenedores?',
    reindexDataConfirmMessage: '¿Está seguro de que desea reindexar los datos?',
    releaseNotes: 'Notas de emisión',
    return_at_pod: 'Regreso en POD',
    save: 'Guardar',
    searchOrgs: 'Organizaciones de búsqueda',
    selected: 'Seleccionado',
    showAll: 'Mostrar todo',
    status: 'ESTADO',
    statusList: {
      'Arrived': 'Arribó',
      'NO_DATA_RECEIVED_FROM_CARRIER': 'Sin datos del operador',
      'BL_NOT_FOUND': 'BL no encontrado',
      'BULK_INIT': 'Contenedor creado',
      'CONTAINER_IDLE_AFTER_GATEOUT': 'Contenedor inactivo después de salir de la puerta',
      'CONTAINER_NOT_FOUND': 'Contenedor no encontrado',
      'CONTAINER_NOT_MAPPED_WITH_BL': 'Contenedor no asignado con BL',
      'CONTAINER_RETURNED_TO_CARRIER': 'Contenedor devuelto al transportista',
      'INIT': 'Contenedor creado',
      'NO_DATA_FROM_PROVIDER': 'Sin información de la naviera',
      'On Time': 'A tiempo',
      'Preparing Predictions': 'Contenedor creado',
      'SUCCESS': 'Éxito',
      'SUCCESS_EARLY_JOURNEY': 'viaje temprano',
      'EARLY_JOURNEY': 'viaje temprano',
      'TARGET_PORT_NOT_IN_ROTATION': 'Puerto no está en itinerario',
      'earlyByXDays': 'Arribo temprano por días',
      'lateByXDays': 'Retraso en arribo por días',
      'delayByDays': 'Retraso {días} día(s)',
    },
    status_code: 'Código de estado',
    stop: 'Parada',
    stops: 'Paradas',
    supplier: 'Proveedor',
    switchActiveOrg: 'Cambiar organización activa',
    switchTab: 'ahora mismo, cambie a otra pestaña',
    t_m_s_ref_no_: 'TMS Ref. No.',
    t_s: 'Transbordo',
    thereAreNo: 'No existen',
    unique: 'Único',
    unknownError: 'Error desconocido',
    unload_at_pod: 'Descargue en POD',
    unload_at_pot: 'Descargue en POT',
    updatedSuccessfully: 'Actualizado con éxito',
    updated_on: 'Actualizado en',
    uploadLogs: 'Subir registros',
    vessel: 'Motonave',
    vesselTracking: 'Seguimiento de motonave',
    vessels: 'Motonaves',
    viewProfile: 'Ver perfil',
    makeAsDefault: 'Hacer como predeterminado',
    containerNumber: 'Número de contenedor',
    billNumber: 'Número de conocimiento de embarque',
    organisation: 'Organización',
    addedUpdated: 'Agregado y actualizado',
    exceptionsIncidents: 'Excepciones e incidentes',
    poNumber: 'numero postal',
    tmsRefNumber: 'Número de referencia TMS',
    content: 'Contenido',
    tableView: 'Vista de tabla',
    calenderView: 'Vista de calendario',
    mapView: 'Vista del mapa',
    customLate: 'tarde personalizado',
    viewName: 'Ver nombre',
    viewDescription: 'Ver descripción',
    optional: 'Opcional',
    setAsDefaultView: ' Establecer como vista predeterminada.',
    portOfLoading: 'Puerto de carga',
    transhipment: 'transbordo',
    transhipments: 'Transbordo(s)',
    portOfDeparture: 'Puerto de descarga',
    search: 'Buscar...',
    routeChange: 'Cambio de ruta',
    anchorageDelay: 'Retraso de anclaje',
    portSequenceChange: 'Cambio de secuencia de puertos',
    carrierConsideringDelay: 'Transportista considerando retraso',
    weatherAdversity: 'Adversidad climática',
    delayAtUpcomingPort: 'Retraso en el próximo puerto',
    carrierDataMissing: 'Faltan datos del operador',
    invalidInput: 'entrada inválida',
    remove: 'Eliminar',
    filters: 'filtros',
    loadingPleaseWait: 'Cargando por favor espere',
    original: 'Original',
    portcast: 'Portcast',
    clearAll: 'Limpiar todo',
    active: 'Activo',
    bookmarked: 'Marcado',
    bookmark: 'Marcador',
    filterAllMessage: 'Todo: todos sus contenedores, incluidos Activo, Archivado y Con error.',
    filterActiveMessage: 'Activo: Contenedores que se rastrean activamente. Esta lista excluye contenedores con error o archivados.',
    filterNewMessage: 'Nuevo: contenedores que se cargaron en las últimas 24 horas.',
    filterLateMessage: 'Tarde: Todos los contenedores activos que están llegando tarde.',
    filterArrivedMessage: 'Llegados: Los siguientes contenedores han llegado a POD.',
    filterBookmarkedMessage: 'Marcado: Los siguientes contenedores han sido marcados por uno de los usuarios de esta organización.',
    selectAllContainers: 'Seleccionar todos los contenedores {count}',
    scheduleAlertMessage: '¿Quiere programar alertas para estos contenedores? Reciba notificaciones sobre hitos clave de contenedores en su correo electrónico o número de teléfono móvil',
    scheduleAlerts: 'Programar alertas',
    dontSchedule: 'no programes',
    bookmarkContainerMessage: '¿Desea marcar estos contenedores? Le ayudará a priorizar los contenedores que son importantes.',
    bookmarkContainers: 'Contenedores de marcadores',
    dontBookmark: 'No marcar',
    downloadContainerMessage: '¿Desea descargar los contenedores?',
    downloadVesselMessage: '¿Quiere descargar la(s) embarcación(es)?',
    download300ContainerMessage: '¿Desea descargar los contenedores seleccionados? La selección de más de 300 contenedores puede tardar más en procesarse.',
    download300VesselMessage: '¿Desea descargar la(s) embarcación(es) seleccionada(s)? La selección de más de 300 recipientes puede tardar más en procesarse.',
    dontDownload: 'Cancelar',
    shareViaEmail: 'Compartir via correo electrónico',
    downloadNow: 'Descargar ahora',
    archiveContainerMessage: '¿Desea archivar los contenedores? El archivado detendrá el seguimiento de los contenedores seleccionados.',
    archiveContainer: 'Contenedor de archivo',
    dontArchive: 'no archivar',
    deleteContainerMessage: 'La eliminación de un contenedor es permanente. Será eliminado de su listado.',
    deleteVesselsMessage: 'La eliminación de una embarcación es permanente. Será eliminado de su listado.',
    deleteContainer: 'Eliminar contenedor',
    deleteVessel: 'Eliminar embarcación',
    dontDelete: 'no borrar',
    eta: 'hora estimada de llegada',
    carrierVessel: 'TRANSPORTADOR/BUQUE',
    action: 'ACCIÓN',
    searchByContainer: 'Buscar por contenedor, B/L o número de orden de compra',
    searchByPodPolTs: 'Buscar por POL, POD o T/S',
    searchByVesselCarrier: 'Buscar por barco o transportista',
    searchByCustomer: 'Buscar por cliente',
    searchByVessel: 'Buscar por barco No o B/L no',
    applyGroupBy: 'Aplicar Agrupar por',
    applySort: 'Aplicar Ordenar',
    showContainers: 'Mostrar Contenedores',
    switchOrganisation: 'Cambiar organización',
    showCountContainers: 'Mostrar {count} contenedores',
    showCountVessels: 'Mostrar {contar} embarcaciones',
    applyFilters: 'Aplicar filtro',
    saveView: 'Guardar vista',
    savedViews: 'Vistas guardadas',
    selectView: 'Seleccione Ver',
    addNew: 'Agregar nuevo',
    chooseView: 'Elija Ver',
    applyView: 'Aplicar vista',
    asDefault: 'por defecto',
    set: 'Colocar',
    viewNameError: 'El nombre de la vista ya existe',
    lateByDays: 'Tarde por {start} to {end} día(s)',
    lateByDaysNumber: 'Tarde por {count}+ día(s)',
    latestEvents: 'Últimos acontecimientos',
    masterOrg: 'Organización principal',
    emptyGateOutAtPOL: 'Salida de puerta vacía (en POL)',
    gateInFullAtPOL: 'Puerta en su totalidad (en POL)',
    loadedAtPOL: 'Cargado (en POL)',
    departedFromPOL: 'Partió (de POL)',
    arrivalAtPOD: 'Llegada (al POD)',
    dischargedAtPOD: 'Descargado (en POD)',
    gateOutFullAtPOD: 'Gate Out Full (en POD)',
    emptyReturnAtPOD: 'Retorno vacío (en POD)',
    lateStatus: 'Estado tardío',
    created: 'Creado',
    updated: 'Actualizado',
    aisSatellite: 'Datos del satélite AIS',
    containerNotFound: 'Contenedor(es) no encontrado(s).<br> Prueba a eliminar algunos filtros.',
    vesselsNotFound: 'Buque(s) no encontrado(s).<br> Prueba a eliminar algunos filtros.',
    comingSoon: 'Muy pronto',
    pleaseTryAgain: 'Vuelva a consultar más tarde e informe para una mayor investigación.',
    port: 'Puerto',
    country: 'País',
    pol_country: 'pol país',
    pod_country: 'país de la vaina',
    clickToCopy: 'Haga clic para copiar',
    clickToCopyContainer: 'Haga clic para copiar Número de contenedor',
    generatingPrediction: 'Contenedor creado',
    latest: 'El último',
    dontHaveAnAccount: '¿Todavía no tienes una cuenta?',
    alreadyHaveAnAccount: '¿Ya tienes una cuenta?',
    corporateEmailInvalidError: 'Utilice el correo electrónico corporativo para registrarse.',
    noOrgDialogTitle: 'Contacto Administración',
    noOrgDialogDescription: 'No tiene una organización asignada, comuníquese con la administración.',
    thankYou: 'Gracias',
    needsAttention: 'Necesita atención',
    onTheWay: 'en camino',
    targetPort: 'Puerto de destino',
    targetPortEta: 'Eta del puerto de destino',
    PredictedActual: 'Previsto * / Real',
    scheduledArrival: 'Llegada Programada',
    errorCode: 'Código de error',
    flaggedOn: 'Marcado en',
    showVessels: 'Mostrar embarcaciones',
    selectAllVessels: 'Seleccionar todos los {count} barcos',
    restart: 'Reanudar',
    reIndexContainers: 'Reindexar contenedores',
    reIndexMessage: '¿Está seguro de que desea reindexar los datos de los contenedores?',
    refresh: 'Actualizar',
    refreshVessels: 'Actualizar recipientes',
    refreshVesselsMessage: '¿Está seguro de que desea actualizar los datos de {count} embarcaciones?',
    downloadSuccess: 'Los contenedores se descargaron correctamente.',
    somethingWentWrong: 'Algo salió mal. Por favor, inténtelo de nuevo más tarde.',
    uploadingLimitExceed: 'Tu cuota de carga superó',
    containerUploadedSuccessfully: 'Los contenedores se cargaron correctamente',
    reportType: 'Tipo de informe',
    timeRange: 'Intervalo de tiempo',
    selectFields: 'Seleccionar campos',
    departingContainers: 'Contenedores de salida',
    departedContainers: 'Contenedores salidos',
    arrivingContainers: 'Contenedores que llegan',
    arrivedContainers: 'Contenedores llegados',
  },
  'containerPage': {
    actual: 'Actual',
    actualArrivalAscending: 'Arribo real - Ascendente',
    actualArrivalDescending: 'Arribo real - Descendante',
    addContainer: 'Añadir contenedor(es)',
    addContainers: 'Agregar Contenedores',
    addNewContainers: 'Agregar nuevos contenedores',
    addMoreContainers: 'AÑADIR MÁS CONTENEDORES',
    addedOn: 'Añadido',
    addedOnMasterOrg: 'Organización / Añadido',
    addedOnSortByItems: {
      created_asc: 'Creado - Ascendente',
      created_desc: 'Creado - Descendente',
    },
    allTab: 'Todos',
    archiveContainers: 'Contenedores archivados',
    archivedTab: 'Archivado',
    arrival: 'Arribo',
    arrivalSortByItems: {
      pod_ais_actual_arrival_asc: 'Arribo real - Ascendente',
      pod_ais_actual_arrival_desc: 'Arribo real - Descendante',
      pod_portcast_predicted_arrival_asc: 'Arribo previso - Ascendente',
      pod_portcast_predicted_arrival_desc: 'Arribo previso - Descendente',
    },
    arrivalStatus: 'Estado de llegada',
    arrivedAtPOD: 'Arribo en POD',
    arrivedAtTS: 'Arribo en puerto de transbordo',
    arrivedTab: 'Arribó',
    arrivingTab: 'Arribando',
    billOfLading: 'Conocimiento de Embarque',
    bookingInfo: 'Información de reserva.',
    bookingInformation: 'Información de reserva',
    bookingNo: 'Nº de reserva',
    bookingNumber: 'Número de reserva',
    browseFiles: 'BÚSQUEDA DE ARCHIVOS',
    bulkUpload: 'Carga masiva',
    carrier: 'Naviera',
    carrierNo: 'Número de naviera',
    carrierSCAC: 'Naviera / SCAC',
    carrierSCACVessels: 'Naviera SCAC / Motonave',
    clear: 'Claro',
    clearAppliedFilters: 'LIMPIAR FILTROS APLICADOS',
    consignee: 'Consignatario',
    containerArchiveSuccessful: '¡Contenedores {count} archivados con éxito!',
    containerEvent: 'Novedad del contenedor',
    containerNo: 'No contenedor',
    containerNoBl: 'Contenedor No / BL',
    containerNotMappedWithBL: 'Contenedor no asignado con BL',
    containerNotTrackedByCarrier: 'Contenedor no rastreado por la naviera',
    containerUploadErrorDuplicate: 'Intento de carga duplicado',
    containerUploadErrorInvalidContainer: 'Número de contenedor no válido',
    containerUploadErrorInvalidCarrier: 'Número de operador no válido',
    containerUploadErrorQuotaExceeded: 'Cuota excedida',
    containerUploadErrorMissingCarrier: 'Naviera no identificada',
    containerUploadErrorMissingContainer: 'No de contenedor faltante',
    contentBrand: 'Contenido / Marca',
    contents: 'Contenido',
    contractualOriginalETA: 'ETA contractual / original',
    contractualOriginalETD: 'ETD contractual/original',
    customer: 'Cliente',
    deletedAllInvalidText: 'ha eliminado todos los contenedores no válidos',
    departedFromPOL: 'Zarpe de POL',
    departedFromTS: 'Zarpe de puerto de transbordo',
    departure: 'Zarpe',
    destination: 'Destino',
    discharge: 'Descargue',
    dischargedAtPOD: 'Descargado en POD',
    dischargedAtTS: 'Descargado en puerto de transbordo',
    disclaimer: 'basado en la fecha inicialmente programada por la naviera',
    downloadAll: 'Descargar todos los contenedores',
    downloadContainers: 'Descargar Contenedores',
    downloadSample: 'Descargar muestra CSV',
    downloadSelected: 'Descargar Contenedores Seleccionados',
    dropFilesHere: 'Soltar archivos aquí',
    earlyJourney: 'viaje temprano',
    earlyTab: 'Temprano',
    emptyReturn: 'Retorno vacío',
    enterData: 'Ingresa tus datos aquí',
    errorCodeFlaggedByTab: 'Código de error / Marcado por',
    exportErrorCSV: 'Exportar errores como CSV',
    fileSupport: 'Archivos pemitidos .CSV, .XLS y .XlSX',
    filterBookingNo: 'Filtrar por números de reserva',
    filterByBLs: 'Filtrar por BL',
    filterByCarriers: 'Filtrar por naviera',
    filterByContainerNos: 'Filtrar por número de contenedor',
    filterByPODPorts: 'Filtrar por puertos POD',
    filterByPOLPorts: 'Filtrar por Puertos POL',
    filterByTSPorts: 'Filtrar por puerto de transbordo',
    filterByVessels: 'Filtrar por motonave',
    filterConsignee: 'Filtrar por consignatario',
    filterContents: 'Filtrar por contenido',
    filterImporter: 'Filtrar por importador',
    filterInternalRef: 'Filtrar por números de referencia internos',
    filterPoNo: 'Filtrar por órdenes de compra',
    filterSupplier: 'Filtrar por Proveedor',
    filterTmsRef: 'Filtrar por números de referencia de T.M.S.',
    finalVesselName: 'Nombre final de la motonave',
    firstVesselName: 'Primer nombre de la motonave',
    flaggedOnTab: 'Marcado en',
    gateInFull: 'Ingreso del contenedor lleno',
    gateOutEmpty: 'Retiro del contenedor vacío',
    gateOutFull: 'Retiro del contenedor lleno',
    generateNewPredictions: {
      disabledTitle: 'La generación de predicciones está deshabilitada para contenedores llegados',
      subTitle: 'Gestionando nuevos ETA para el contenedor',
      successMessage: 'Gestionando nuevas predicciones. Vuelva a consultar después de 1-2 minutos.',
      title: 'Generar nuevas predicciones',
    },
    groupBy: 'Agrupar por',
    groupingByUnique: 'Agrupación por Único',
    importer: 'Importador',
    initiallyScheduled: 'inicialmente programado',
    plannedArrival: 'inicialmente programado',
    planned: 'Inicial',
    internalRef: 'Referencia interna',
    internalRefNumber: 'Número de referencia interna',
    invalidContainerNo: 'Número de contenedor no válido',
    journey: 'Viaje',
    lateTab: 'Retrasado',
    latestEvent: 'Último Evento',
    latestPredicted: 'Último (previsto)',
    latestScheduled: 'Último programado',
    loadedAtPOL: 'Cargado en POL',
    loadedAtTS: 'Cargado en puerto de transbordo',
    loading: 'Cargando',
    mapColumns: 'Columnas del mapa',
    mapColumnsHeader: '¿La fila seleccionada contiene encabezados de columna?',
    mapColumnsTip: 'Seleccione la columna correspondiente del archivo',
    miscellaneousInformation: 'Información miscelánea',
    newTab: 'Nuevo',
    nextStepsInstruction: 'El siguiente paso le permitirá hacer coincidir las columnas de su hoja de cálculo con los puntos de datos correctos.',
    noDataFromCarrier: 'Sin información de la naviera',
    noInvalidContainers: 'No hay contenedores inválidos',
    noSailingInfo: 'Sin información de zarpe',
    outboundHaulage: 'Transporte saliente',
    outboundHaulageID: 'ID de transporte de salida',
    packageQuantity: 'Cantidad de paquetes',
    poNo: 'PO No.',
    poNumber: 'Número de P.O ',
    pod: 'POD',
    pol: 'POL',
    portNotinSchedule: 'Puerto no está en itinerario',
    predicted: 'Predicho',
    predictedActualArrival: 'Arribo previsto/real',
    predictedArrivalAscending: 'Arribo previsto - Ascendente',
    predictedArrivalDescending: 'Arribo previsto - Descendente',
    preparingPredictions: 'Contenedor creado',
    productDescription: 'Descripción del producto',
    productDetails: 'Detalles de producto',
    productInfo: 'Información del producto.',
    productInformation: 'Información del Producto',
    redFlagComments: 'Ingrese comentarios, o cualquier otra información que desee agregar',
    redFlagContainer: 'Contenedores de bandera roja',
    redFlaggedSuccessfulMessage: 'Contenedores marcados en rojo {count} con éxito. También puede encontrarlos en la pestaña Contenedores marcados con bandera roja',
    redFlaggedTab: 'Bandera roja',
    refreshOrgData: 'Actualizar datos',
    reindexData: 'Reindexar datos',
    reviewContainers: 'Revisar contenedores',
    sailingDates: 'Fecha de zarpe',
    sailingInfo: 'Información de zarpe',
    sailingInfoNotAvailable: 'Información de zarpe no disponible debido a la falta de información de POL o POD.',
    saleInfo: 'Información de zarpe',
    salesOrder: 'Órdenes de venta',
    scheduled: 'Programado',
    searchContainers: 'Búsqueda por contenedores',
    selectAnotherRow: 'Seleccionar otra fila',
    selectReasonForRedFlag: 'Seleccionar motivos para marcar',
    showEtaHistory: 'Mostrar historial de ETA',
    sortBy: 'Ordenar por',
    sortByAddedOnDate: 'Ordenar por Añadido',
    sortByArrivalDate: 'Ordenar por fechas de llegada',
    source: 'Fuente',
    sourceFile: 'Archivo fuente',
    status: 'Estado',
    statusIncidents: 'Estado / Incidentes',
    subtextAddContainers: 'Puede copiar y pegar varias filas de su archivo de origen aquí.',
    success: 'Éxito',
    supplier: 'Proveedor',
    supplierID: 'Identificación del proveedor',
    title: 'Seguimiento de contenedores',
    tmsRef: 'Ref. T.M.S.',
    tmsReferenceNumber: 'Número de referencia TMS',
    ts: 'Transbordo',
    unflagContainers: 'Desmarcar contenedores',
    updatedOn: 'Actualizado en',
    uploadAddContainers: 'Cargar/Agregar contenedores',
    uploadCompleted: 'Carga completada',
    uploadFailed: 'Subida fallida',
    uploadFailedMessage: 'No se han podido cargar los contenedores.',
    uploadMessage1: 'Cargue exitoso',
    uploadMessage2: 'contenedores con éxito',
    vessel: 'Motonave',
    vesselNotConfirmed: 'Motonave no confirmada',
    vesselNotConfirmedTooltip: 'El Transportista aún debe confirmar el nombre del buque para este tramo. Vuelva a consultar en 48 horas',
    vesselVoyage: 'Motonave, viaje',
    viewRecentUploadLogs: 'Ver registros de contenedores cargados recientemente',
    search: 'Buscar',
    origin: 'Origen',
    selectPort: 'Seleccionar puerto',
    selectCountry: 'Seleccionar país',
    addingContainerInProgress: 'Agregando contenedor en progreso...',
    AddCountContainers: 'Agregar {count} contenedores',
    new: 'Nuevo',
    createReport: 'Crear reporte',
  },
  'containerUploadLogsPage': {
    all: 'Todos',
    billOfLadingNo: 'Conocimiento de Embarque No.',
    carrierScac: 'Naviera SCAC',
    containerNo: 'No contenedor.',
    duplicates: 'Duplicados',
    error: 'Error',
    invalidContainers: 'Contenedores no válidos',
    invalidPayload: 'Payload inválido',
    invalidScac: 'SCAC no válidos',
    noError: 'No hay error',
    outOfQuota: 'fuera de cuota',
    recentUploads: 'Subidas recientes',
    search: 'Búsqueda por BOL, número de contenedor, transportista o errores',
    title: 'Registros de carga de contenedores',
    uploadedDatetime: 'Fecha y hora de carga',
    validationStatus: 'Estado de validación',
  },
  'dashboardPage': {
    containersAtPOD: 'Contenedores en POD',
    containersAtPODToolTip: 'Hitos de contenedores en el POD',
    containersAtTS: 'Contenedores en puerto de transbordo',
    containersAtTSToolTip: 'Contenedores actualmente en Transbordo',
    delay10OrMoreDays: '10+ Días',
    delay1To4Days: 'Retraso adicional 1-4 días',
    delay5To9Days: '5-9 días',
    delayedContainers: 'Contenedores Retrasados',
    delayedContainersToolTip: 'Distribución de contenedores que arriban temprano, a tiempo o tarde',
    departureDelayAtPOL: 'Retraso de zarpe en POL',
    departureDelayAtPOLToolTip: 'Distribución del retraso en zarpe del POL',
    incidentToolTip: 'Distribución de contenedores con incidencias',
    late1To2Days: 'Retraso: 1-2 Días',
    late3To5Days: 'Retraso: 3-5 Días',
    late5OrMoreDays: 'Retraso: 5+ Días',
    problematicContainers: 'Contenedores con novedades',
    problematicContainersToolTip: 'Distribución de contenedores con los siguientes problemas. Contenedor no rastreado por la naviera, sin información de de la naviera, contenedor no asignado con BL, viaje anticipado, puerto fuera de horario',
    title: 'Tablero',
    topTradeLanesUsed: 'Principales rutas comerciales utilizadas',
    topTradeLanesUsedToolTip: 'Recuento de contenedores que se dirigen desde pares de puertos únicos',
    totalBLs: 'BL totales',
    totalBLsToolTip: 'BL únicos actualmente rastreados por Portcast',
    totalContainers: 'Contenedores Totales',
    totalContainersToolTip: 'Contenedores únicos actualmente rastreados por Portcast',
    totalContainersToolTipItalics: 'Retrasado (en el mar) + Arribado (en POD) + Con novedades',
    totalVesselVoyages: 'Motonaves totales / Viajes',
    totalVesselVoyagesToolTip: 'Motonaves/viajes únicos actualmente rastreados por Portcast',
    waiting10OrMoreDays: '10+ Días',
    waiting5To10Days: '5-10 días',
    waitingTime1To3Days: 'Tiempo de espera 1-3 días',
    overview: 'Visión general',
    exceptions: 'Excepciones',
    analytics: 'Analítica',
    viewAll: 'Ver todo',
    breakdownLateContainers: 'Desglose de contenedores tardíos',
    totalTracked: 'Seguimiento total',
    active: 'Activo',
    activeTooltip: 'Subconjunto de contenedores que actualmente están siendo rastreados por Portcast',
    withError: 'con error',
    withErrorTooltip: 'Subconjunto de contenedores a los que les faltan datos de seguimiento debido a un error',
    inactive: 'Inactivo/Archivado',
    inactiveTooltip: 'Subconjunto de contenedores que ya no se rastrean. Los siguientes incluyen contenedores que se han archivado de forma manual o programática (es decir, 30 días después de que se hayan capturado los hitos del último evento del contenedor).',
    totalActive: 'Activo total',
    journeyTBC: 'Viaje por confirmar',
    journeyTBCTooltip: 'Esos contenedores están demasiado adelantados en su viaje y no hay datos de seguimiento marítimo disponibles; o el transportista no ha confirmado el tramo final del viaje en barco',
    arrivedAtPOD: 'Llegué a POD',
    arrivedAtPODTooltip: 'Esos contenedores han llegado con éxito al POD.',
    journeyInProgress: 'Viaje por el océano en curso',
    journeyInProgressTooltip: 'Esos contenedores están actualmente en tránsito hacia el POD.',
    withErrors: 'con errores',
    carrierDataMissing: 'Faltan datos del operador',
    carrierDataMissingTooltip: 'El transportista no está devolviendo ningún dato. Esos envíos pueden ser demasiado pronto o ya han caducado. Qué sigue: Portcast buscará datos nuevos o actualizados. Intente comprobar de nuevo en 24 horas.',
    invalidInput: 'entrada inválida',
    invalidInputTooltip: 'Esos podrían ser errores tipográficos. Los siguientes contenedores no parecen pertenecer a los BL/Números de reserva que se proporcionaron. Qué sigue: Vuelva a verificar si se ha utilizado el Número de contenedor, BL/Números de reserva correctos.',
    unknownError: 'Error desconocido',
    unknownErrorTooltip: 'Ha ocurrido un error interno al procesar esos contenedores. Qué sigue: Estamos investigando esos problemas. Vuelva a consultar en 12 horas.',
    commonReasonsForDelay: 'Razones comunes de retraso',
    portStay: 'Estancia en puerto',
    portStayTooltip: 'Este incidente se muestra cuando un buque/contenedor ha pasado más tiempo esperando en el área del puerto antes de la llegada al puerto.',
    anchorageStay: 'Estancia en Anchorage',
    anchorageStayTooltip: 'Este incidente se muestra siempre que un buque/contenedor ha pasado más tiempo esperando en la zona de fondeo antes de la llegada al puerto.',
    nonOptimalRoute: 'Ruta no óptima',
    nonOptimalRouteTooltip: 'Este incidente se muestra cuando el buque ya no sigue una ruta históricamente óptima',
  },
  'infiniteLoading': {
    noMore: 'No se encontraron más datos',
    noResults: 'No se encontraron más registros',
    spinner: 'Cargando ...',
  },
  'loginPage': {
    confirmNewPassword: 'Confirmar nueva contraseña',
    confirmNewPasswordPlaceholder: 'Re-ingrese nueva contraseña',
    emailInvalidError: 'Email inválido',
    emailPlaceholder: 'Ingrese el correo electrónico registrado',
    workEmailPlaceholder: 'Ingrese su correo electrónico de trabajo',
    workEmailValidError: 'Utilice la dirección de correo electrónico de su trabajo para registrarse.',
    emailRequiredError: 'correo electronico es requerido',
    firstName: 'Primer nombre',
    firstNameEnter: 'Ingrese el nombre',
    forgotPassword: 'Has olvidado tu contraseña',
    lastName: 'Apellido',
    lastNameEnter: 'Introduzca el apellido',
    login: 'Iniciar sesión',
    newPassword: 'Nueva contraseña',
    newPasswordEnter: 'Ingrese nueva clave',
    passwordDoNotMatch: 'Las contraseñas no coinciden',
    passwordInvalidError: 'Contraseña invalida',
    passwordPlaceholder: 'Introducir la contraseña',
    passwordRequiredError: 'se requiere contraseña',
    passwordResetSuccess: '¡Restablecimiento de contraseña exitoso! Intente iniciar sesión',
    resetPassword: 'Restablecer la contraseña',
    sendResetCode: 'Enviar código de reinicio',
    title: 'Iniciar sesión en',
    verificationCode: 'Código de verificación',
    verificationCodeEnter: 'Ingrese el código de verificación',
    verificationCodeRequired: 'Se necesita un código de verificación',
    welcome: 'Bienvenido a',
    signUp: 'Inscribirse',
    signUpTitle: 'Crea tu cuenta Portcast',
    signUpDescription: 'Ingrese su correo electrónico comercial. Las credenciales de su cuenta y la contraseña se enviarán a este correo electrónico.',
    registeredSuccessfully: 'Registrado correctamente',
    signUpMessage: '¡Creación de cuenta en marcha! Tendrá noticias nuestras en 24 horas. Mientras tanto, vea nuestro video del producto para tener una idea de lo que ofrecemos.',
  },
  'portLookupPage': {
    code: 'Código',
    country: 'País',
    countryCode: 'Código de país',
    latitude: 'Latitud',
    longitude: 'Longitud',
    ports: 'Puertos',
    search: 'Buscar nombres de puerto o códigos de puerto',
  },
  'profilePage': {
    masterOrg: 'Organización maestra',
    noOfUsers: 'Nº de Usuarios',
    orgApiKey: 'Clave de API de la organización',
    orgIdInfo: ' Incluya el ID de la organización en la plantilla de carga de correo electrónico. Descargue la plantilla de muestra a continuación.',
    organisationApiKey: 'Clave de API de la organización',
    organizationId: 'Identificación de la organización',
    organizationName: 'Nombre de la Organización',
    organizations: 'Organizaciones',
    quotaUtilization: 'Utilización de cuotas',
    searchOrganizations: 'Buscar Organizaciones',
    title: 'Perfil del usuario',
    userApiKey: 'Clave de API de usuario',
    userDetails: 'Detalles de usuario',
    userID: 'ID de usuario',
    viewDetails: 'Ver detalles',
    autoArchive: 'Archivo automático',
    addCustomField: 'Agregar campo personalizado',
    create: 'Crear',
    fieldName: 'Nombre del campo',
    fieldType: 'Tipo de campo',
    allowMultipleValues: 'Permitir múltiples valores',
    subFields: 'Subcampos',
    newCustomField: 'Nuevo campo personalizado',
  },
  'singleContainerPage': {
    billOfLadingNo: 'conocimiento de embarque no',
    copyUrl: 'Copiar URL',
    destination: 'Destino',
    detailedEvents: 'Eventos detallados',
    goBack: 'Regresar',
    historical: 'HISTÓRICO',
    incidents: 'incidentes',
    lastUpdated: 'Última actualización (UTC)',
    location: 'Ubicación',
    mapView: 'Vista del mapa',
    modeOfTransport: 'Modo de transporte',
    ooops: '¡Uy!',
    predicted: 'PREDICHO',
    predictedActual: 'Previsto */Real',
    resetZoom: 'Restablecer acercamiento',
    somethingWentWrong: 'Algo salió mal',
    source: 'Fuente',
    conversations: 'Conversaciones',
    noMessageFound: 'No se encontraron conversaciones para este contenedor',
    messagePlaceholder: 'Agrega tu comentario aquí... Usa @ para etiquetar personas. Se les enviará un correo electrónico.',
    noMessageFoundError: 'Se produjo un error al obtener conversaciones. Inténtalo de nuevo.',
    fetchingMessages: 'Obteniendo conversaciones...',
    tryAgainLater: 'Vuelva a intentarlo en algún momento',
  },
  'vesselsLookupPage': {
    breadthExtreme: 'Amplitud Extrema',
    callSign: 'Señal de llamada',
    deadWeight: 'Peso muerto',
    draught: 'Reclutar',
    engineBuilder: 'Constructor de motores',
    enginePower: 'Potencia del motor',
    engineStroke: 'Carrera del motor',
    grossTonnage: 'Toneladas brutas',
    search: 'Buscar por motonave',
    speed: 'Velocidad',
    type: 'Escribir',
    vessels: 'Motonvave',
  },
  'navBar': {
    'control_tower_dashboard': 'Torre de control',
    'co2_report_dashboard': 'Informe de CO2',
    'port_congestion': ' Congestion de los puertos',
    'historicalTransit': 'Tiempo de tránsito',
    'dashboard': 'Tablero de control',
    'exception_dashboard': 'Excepciones',
    'historical_transit_time': 'Tiempo de tránsito histórico',
    'accuracy_dashboard': 'Exactitud',
    'latency_accuracy_dashboard': 'Cobertura y latencia',
    'vessel-tracking': 'Seguimiento de embarcaciones',
    'report-dashboard': 'Generador de informes',
    'report-builder': 'Generador de informes',
    'login': 'Acceso',
    'profile': 'Página de perfil: {iniciales}',
    'container-tracking': 'Seguimiento de contenedores',
    'container-tracking-id': '{id del contenedor}',
    'container-tracking-id-email': '{containerId}: seguimiento de un solo contenedor',
    'container-tracking-master-report': 'Informe maestro',
    'container-tracking-api': 'Respuesta API',
    'container-tracking-cached': 'Marcador en caché',
    'container-tracking-typesense': 'Objeto de índice de búsqueda (Typesense)',
    'upload-logs-container': 'Registros de contenedores',
    'upload-logs-vessel': 'Registros de embarcaciones',
    'sailing-schedule': 'Horarios de navegación',
    'metadata_ports': 'Metadatos del puerto',
    'metadata_carriers': 'Metadatos del operador',
    'metadata_vessels': 'Metadatos de embarcaciones',
  },
  'onBoarding': {
    title: 'Empezando',
  },
  'Arrived at POD': 'Arribo en POD',
  'Arrived at T/S': 'Arribo en puerto de transbordo',
  'Departed from POL': 'Zarpe d POL',
  'Departed from T/S': 'Zarpe de puerto de transbordo',
  'Discharged at POD': 'Descargado en POD',
  'Discharged at T/S': 'Descargado en puerto de transbordo',
  'Empty Return': 'Retorno vacío',
  'Gate In Full': 'Ingreso del contenedor lleno',
  'Gate Out Empty': 'Retiro del contenedor vacío',
  'Gate Out Full': 'Retiro del contenedor lleno',
  'Loaded at POL': 'Cargado en POL',
  'Loaded at T/S': 'Cargado en puerto de transbordo',
  'v2': {
    sideNav: {
      addContainers: 'Agregar Contenedores',
      addContainer: 'Agregar contenedor',
      addContainersSubtitle: 'Quedan {count} créditos',
      creditMinimumWarningModalText: 'Su límite de crédito actual se está acercando. Para un aumento, por favor póngase en contacto con nosotros.',
      creditZeroErrorModalText: ' Su límite de crédito actual se ha agotado. No puede cargar más contenedores. Para solicitar un aumento, por favor póngase en contacto con nosotros.',
      dashboard: 'Descripción general',
      tracking: 'Seguimiento',
      vesselTracking: 'Buque de pista',
      containerTracking: 'Contenedor de pista',
      reports: 'Informes',
      portcast_indices: 'Índices de transmisión portuaria',
      portCongestion: 'Congestion de los puertos',
      developerTools: 'Herramientas de desarrollo',
      containerLogs: 'Registros de contenedores',
      vesselLogs: 'Registros de embarcaciones',
      carrierLookup: 'Búsqueda de operador',
      portLookup: 'Búsqueda de puerto',
      vesselLookup: 'Búsqueda de embarcaciones',
      helpCenter: 'Buque de seguimiento',
      knowledgeBase: 'Base de conocimientos',
      reportAnIssue: 'Reportar un problema',
      changeLog: 'Registro de cambios',
      apiDocs: 'Documentos API',
      profile: 'Mi perfil',
      logout: 'Cerrar sesión',
    },
    singleContainerPage: {
      plannedArrival: 'inicialmente programado',
      originalETA: 'hora estimada de llegada original',
      latestETA: 'Último programado',
      predictedETA: 'ETA prevista',
      downloadReport: 'Descargar Informe',
      download: 'Descargar',
      shareURL: 'Compartir URL',
      share: 'Compartir',
      shareURLToolTip: 'Haga clic aquí para copiar la URL',
      shareURLCopyMessage: 'URL copiada en el portapapeles',
      seeRelatedShipments: 'Ver envíos relacionados',
      seeAllShipmentsOnVessel: 'Ver todos los envíos en barco',
      noRelatedShipments: 'No se encontraron otros contenedores con este número B/L',
      viewContainerDetails: 'Ver eventos detallados',
      blNo: 'Número de B/L',
      newUI: 'Nueva interfaz de usuario',
      oldUI: 'Interfaz de usuario antigua',
      tabs: {
        detailedEvents: {
          title: 'Eventos detallados',
        },
        productDetails: {
          title: 'Detalles de producto',
        },
        terminalDetails: {
          title: 'Detalles de la terminal',
        },
        conversations: {
          title: 'Conversaciones',
        },
      },
      arrivalAtPOD: 'Llegada al POD',
      carrierArrival: 'Llegada del transportista',
      blNumber: 'Número de B/L',
      carrierLatestVessel: 'Transportista y última embarcación',
    },
    noLoginBanner: {
      title: 'Únete a Portcast para obtener',
      point1: 'Todos los datos de seguimiento de contenedores de Carrier en un solo lugar',
      point2: 'No más seguimiento manual para sus equipos',
      point3: 'Actualizaciones automáticas a su correo electrónico y móvil',
      requestAccess: 'Solicitar acceso',
    },
    bulkUpload: {
      containerBulkUpload: 'Método 1: agregar contenedores a granel',
      containerManuallyUpload: 'Método 2: agregar contenedores manualmente',
      bulkUploadText: 'Suelte sus archivos aquí o haga clic aquí para agregar archivos',
      uploadSupportFile: 'Admitimos .CSV, .XLS y .XlSX',
      downloadSampleUploadFile: 'Descargar archivo de carga de muestra',
      addManuallyContainers: 'Haga clic aquí para agregar contenedores manualmente',
      addingManuallyTakeMoreTime: 'Agregarlos manualmente normalmente lleva más tiempo',
      mapColumns: 'Asigne las columnas del archivo subido a los campos siguientes',
      addContainersManually: 'Agregar contenedores manualmente',
    },
  },
  'welcomeDialog': {
    title: 'La bienvenida a bordo !!',
    description: 'Para comenzar a rastrear sus contenedores, el primer paso es agregarlos. Para iniciar el proceso de carga, simplemente haga clic en el botón "Agregar contenedor".',
    processingTitle: 'Procesando subidas...',
    processingDescription: 'Esto puede tomar un par de minutos. Le notificaremos una vez que los contenedores se carguen correctamente.',
  },
  'co2EmissionLockDialog': {
    title: 'Emisiones de CO2e bloqueadas',
    description: 'Actualmente, las emisiones de CO2 están bloqueadas para su cuenta. Comuníquese con nosotros para desbloquear los datos de emisiones.',
  },
  'predictionsLockDialog': {
    title: 'Predicciones bloqueadas',
    description: '¿Le gustaría desbloquear las predicciones para la visibilidad de los contenedores? Las predicciones son más precisas y se actualizan cada día. Comuníquese con nosotros para desbloquear las predicciones.',
  },
}
